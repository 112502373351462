// 관리자 목록
import { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";
// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	amIdx: number;
	depart: string;
	name: string;
	id: string;
	joinDt: string;
	lastLoginDt: string;
	grantYn: number;
	grade: number | null;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [grant, setGrant] = useState<string[]>([]);
	const [grade, setGrade] = useState<string[]>([]);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const filterList = [
		{
			title: "활동 구분",
			name: "grant",
			items: [
				{ text: "승인", value: 1 },
				{ text: "미승인", value: 0 },
				{ text: "탈퇴", value: -1 },
			],
			option: grant,
			setOption: setGrant,
		},
		{
			title: "권한 등급",
			name: "grade",
			items: [
				{ text: "뷰어", value: 1 },
				{ text: "뷰어+편집", value: 2 },
				{ text: "권한 제외", value: 0 },
				{ text: "권한 미부여", value: "N" },
				{ text: "마스터", value: 3 },
			],
			option: grade,
			setOption: setGrade,
		},
	];

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);
	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		await Api.get("manager/managerList", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.datas);
					setTotalItems(res.data.data.cnt.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 활동 구분
	const grantText = (grantYn: number) => {
		switch (grantYn) {
			case 0:
				return "미승인";
			case 1:
				return "승인";
			case -1:
				return "탈퇴";
			default:
				return "-";
		}
	};
	// 권한 등급
	const gradeText = (grade: number | null) => {
		switch (grade) {
			case 3:
				return "마스터";
			case 2:
				return "뷰어+편집";
			case 1:
				return "뷰어";
			case 0:
				return "권한 제외";
			case null:
				return "권한 미부여";
			default:
				return "-";
		}
	};
	// 관리자 정보 수정 팝업 오픈
	const openEditPopup = (amIdx: number) => {
		window.open(
			"/ADMI001002?amIdx=" + amIdx,
			"_blank",
			"width=600, height=800, left=100, top=100"
		);
	};

	// 공통
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setGrant([]);
		setGrade([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = { grant: grant, grade: grade.join() };
		pageResset();
		setIsApplied(grant.length > 0 || grade.join() !== "");
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};

	return (
		<Page
			title="관리자 목록"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<select
								name="searchKey"
								id="searchKey"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchKey: e.target.value,
									})
								}
							>
								<option value="">= 선 택 =</option>
								<option value="1">관리자번호</option>
								<option value="2">아이디</option>
								<option value="3">부서</option>
								<option value="4">이름</option>
							</select>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
								className={`${isApplied && "applied"}`}
							>
								{isOpenFilter ? (
									<Minus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								) : (
									<Plus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								)}
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<S.filterWrap>
								{filterList.map((filter: any, fi: number) => {
									return (
										<li key={fi}>
											<h2>{filter.title}</h2>
											<ul>
												<Filter
													fi={fi}
													filter={filter} // 필터 아이템 리스트
													option={filter.option}
													setOption={filter.setOption}
												/>
											</ul>
										</li>
									);
								})}
							</S.filterWrap>
							<S.flexRowEnd>
								<button onClick={() => filter()}>적용</button>
								<button onClick={() => filterRefresh()}>
									전체 해제
								</button>
							</S.flexRowEnd>
						</S.filterArea>
					</S.searchArea>
					<S.blank1 />
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th>관리자번호</th>
									<th>부서명</th>
									<th>이름</th>
									<th>아이디</th>
									<th
										className="sort down"
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
											});
											pageResset();
										}}
									>
										가입 일자
									</th>
									<th>최근 접속 일시</th>
									<th>활동 구분</th>
									<th>권한 등급</th>
									<th>관리</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{item.amIdx}</td>
												<td>{item.depart}</td>
												<td>{item.name}</td>
												<td>{item.id}</td>
												<td>
													{item.joinDt !== null
														? Util.dateFormat(
																item.joinDt.toString()
														  )
														: "-"}
												</td>
												<td>
													{item.lastLoginDt !== null
														? Util.dateFormat(
																item.lastLoginDt
														  )
														: "-"}
												</td>
												<td>
													{grantText(item.grantYn)}
												</td>
												<td>{gradeText(item.grade)}</td>
												<td>
													<S.tableButton
														onClick={() =>
															viewerCheck(() =>
																openEditPopup(
																	item.amIdx
																)
															)
														}
													>
														<S.headerText>
															수정
														</S.headerText>
													</S.tableButton>
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};

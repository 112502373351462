// 로그인
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// 캘린더
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
// 차트
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import * as S from "../Styles/Style";
import Header from "../Components/Header";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";

const Dashboard = styled(S.page)`
	background-color: ${S.color.primaryLight};
	padding: 1rem;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	overflow-y: scroll;
`;
const DashboardBoxTitle = styled(S.flexRowBetween)`
	margin-bottom: 2rem;
	h2 {
		font-size: 1.4rem;
	}
`;
const DashboardBox = styled.div`
	min-width: 30%;
	background-color: white;
	padding: 2rem;
	flex-grow: 1;
	margin: 1rem;
	border-radius: 0.7rem;
	display: flex;
	flex-direction: column;
	& > table {
		border-collapse: collapse;
		width: 100%;
		flex-grow: 1;
		th,
		td {
			font-size: 1.2rem;
			border: 1px solid ${S.color.gray02};
			border-left: 0;
			padding: 0.5rem 1rem;
		}
		th {
			text-align: left;
		}
		td {
			text-align: right;
		}
		tr > td:last-child {
			border-right: 0;
		}
	}
	textarea {
		height: 100%;
		background-color: ${S.color.gray01};
		border-radius: 0.7rem;
		padding: 1rem;
	}
`;
const FullCalendarArea = styled(S.flexRowBetween)`
	height: 100%;
	& > div {
		width: calc(50% - 1rem);
		max-width: 60rem;
	}
	.fc .fc-button-primary {
		background-color: transparent;
		border: 0;
	}
	.fc .fc-event-title {
		color: white;
	}
	.fc .fc-daygrid-day.fc-day-today {
		background-color: ${S.color.primaryLight};
	}
`;
const EventList = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	h3 {
		font-size: 1.4rem;
		text-align: center;
		margin-bottom: 2rem;
	}
	ul {
		flex-grow: 1;
	}
	li {
		border-bottom: 1px solid ${S.color.gray02};
		padding: 1rem;
		display: flex;
		align-items: center;
	}
	h4 {
		font-size: 1.2rem;
	}
`;
const EventImage = styled.div`
	border: 1px solid ${S.color.gray01};
	background-color: ${S.color.gray01};
	width: 5rem;
	height: 5rem;
	margin-right: 1rem;
	border-radius: 0.5rem;
`;

export default () => {
	const navigate = useNavigate();

	// const { data: scheduleList } = {
	// 	data: [
	// 		{
	// 			title: "dfsdfsfd",
	// 			start: new Date(),
	// 			allDay: true,
	// 			openDate: new Date(),
	// 			isAllDay: true,
	// 		},
	// 	],
	// };
	// 캘린더 날짜 클릭 이벤트
	const onDateClick = ({ date }: DateClickArg) => {
		alert(date);
	};
	// 차트
	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
	);
	const labels = ["0", "1", "2", "3", "4", "5", "6"]; //x축 기준
	return (
		<>
			<Header />
			<Dashboard>
				<DashboardBox>
					<DashboardBoxTitle>
						<h2>매출</h2>
						<a>
							<Plus
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
						</a>
					</DashboardBoxTitle>
					<table>
						<tbody>
							<tr>
								<th>금일 매출액</th>
								<td>0원</td>
							</tr>
							<tr>
								<th>월 매출액</th>
								<td>0원</td>
							</tr>
							<tr>
								<th>골드상자 뽑기 횟수</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>스페셜상자 뽑기 횟수</th>
								<td>0개</td>
							</tr>
						</tbody>
					</table>
				</DashboardBox>
				<DashboardBox>
					<DashboardBoxTitle>
						<h2>1:1 문의</h2>
						<a href="">
							<Plus
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
						</a>
					</DashboardBoxTitle>
					<table>
						<tbody>
							<tr>
								<th>답변 전</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>답변 중</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>답변 완료</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>답변 지연</th>
								<td>0개</td>
							</tr>
						</tbody>
					</table>
				</DashboardBox>
				<DashboardBox>
					<DashboardBoxTitle>
						<h2>배송현황</h2>
						<a href="">
							<Plus
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
						</a>
					</DashboardBoxTitle>
					<table>
						<tbody>
							<tr>
								<th>배송 전</th>
								<td>0개</td>
								<th>배송 지연</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>배송 준비</th>
								<td>0개</td>
								<th>배송 취소</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>배송 중</th>
								<td>0개</td>
								<th>발송 완료</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>배송 완료</th>
								<td>0개</td>
								<th>발송 실패</th>
								<td>0개</td>
							</tr>
							<tr>
								<th>배송 대기</th>
								<td>0개</td>
								<th>판매 중단</th>
								<td>0개</td>
							</tr>
						</tbody>
					</table>
				</DashboardBox>
				<DashboardBox>
					<DashboardBoxTitle>
						<h2>이벤트 일정</h2>
						<a href="">
							<Plus
								fill={S.color.gray03}
								width="2rem"
								height="2rem"
							/>
						</a>
					</DashboardBoxTitle>
					<FullCalendarArea>
						<FullCalendar
							locale={"kr"}
							// initialView=""
							plugins={[dayGridPlugin, interactionPlugin]}
							events={[
								{
									title: "event 1",
									start: "2024-03-08T12:30:00",
									end: "2024-03-11T12:30:00",
									color: S.color.primary,
									allDay: true,
								},
								{
									title: "event 2",
									start: "2024-03-01T12:30:00",
									end: "2024-03-12T12:30:00",
									color: S.color.primaryVariant,
									allDay: true,
								},
								{
									title: "event 3",
									date: "2024-04-02",
									color: S.color.primary,
								},
								{
									title: "event 4",
									start: "2024-03-21T12:30",
									end: "2024-04-12T12:30",
									color: S.color.primaryVariant,
									allDay: true,
								},
							]}
							contentHeight="auto"
							dateClick={onDateClick}
							eventClick={() => {}}
							headerToolbar={{
								left: "prev",
								center: "title",
								right: "next",
							}}
						/>
						<EventList>
							<h3>0000년 00월 00일</h3>
							<ul>
								<li>
									<EventImage></EventImage>
									<div>
										<h4>이벤트 1</h4>
										<p>
											0000년 00월 00일 ~ 0000년 00월 00일
										</p>
									</div>
								</li>
								<li>
									<EventImage></EventImage>
									<div>
										<h4>이벤트 2</h4>
										<p>
											0000년 00월 00일 ~ 0000년 00월 00일
										</p>
									</div>
								</li>
								<li>
									<EventImage></EventImage>
									<div>
										<h4>이벤트 3</h4>
										<p>
											0000년 00월 00일 ~ 0000년 00월 00일
										</p>
									</div>
								</li>
							</ul>
						</EventList>
					</FullCalendarArea>
				</DashboardBox>
				<DashboardBox>
					<DashboardBoxTitle>
						<h2>메모</h2>
					</DashboardBoxTitle>
					<S.tabArea>
						<button className="active">
							<S.headerText>개인</S.headerText>
						</button>
						<button>
							<S.headerText>공유</S.headerText>
						</button>
					</S.tabArea>
					<S.blank2 />
					<textarea
						name="memo"
						id="memo"
						cols={30}
						rows={10}
					></textarea>
				</DashboardBox>
				<DashboardBox>
					<DashboardBoxTitle>
						<h2>주요현황</h2>
					</DashboardBoxTitle>
					<S.tabArea>
						<button className="active">
							<S.headerText>회원관리</S.headerText>
						</button>
						<button>
							<S.headerText>상품관리</S.headerText>
						</button>
						<button>
							<S.headerText>거래관리</S.headerText>
						</button>
						<button>
							<S.headerText>매출관리</S.headerText>
						</button>
						<button>
							<S.headerText>배송관리</S.headerText>
						</button>
					</S.tabArea>
					<S.blank2 />
					<S.flexRowBetween>
						<table>
							<thead>
								<tr>
									<th>날짜</th>
									<th>전체회원</th>
									<th>신규회원</th>
									<th>탈퇴회원</th>
									<th>휴면회원</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
								<tr>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
									<td>0</td>
								</tr>
							</tbody>
						</table>
						<Line
							width="600"
							height="300"
							options={{
								responsive: false,
								plugins: {
									legend: {
										position: "top",
									},
									// title: {
									// 	display: true,
									// 	text: "지난 7일 간 채팅 건수",
									// },
								},
							}}
							data={{
								labels,
								datasets: [
									{
										label: "전체", //그래프 분류되는 항목
										data: [1, 2, 3, 4, 5, 6, 7], //실제 그려지는 데이터(Y축 숫자)
										borderColor: S.color.point01, //그래프 선 color
										backgroundColor: S.color.point01,
									},
									{
										label: "신규",
										data: [2, 3, 4, 5, 4, 7, 8],
										borderColor: S.color.point02, //실제 그려지는 데이터(Y축 숫자)
										backgroundColor: S.color.point02,
									},
									{
										label: "탈퇴",
										data: [2, 1, 1, 2, 3, 2, 3],
										borderColor: S.color.point03, //실제 그려지는 데이터(Y축 숫자)
										backgroundColor: S.color.point03,
									},
									{
										label: "휴면",
										data: [0, 1, 0, 3, 2, 1, 0],
										borderColor: S.color.point04, //실제 그려지는 데이터(Y축 숫자)
										backgroundColor: S.color.point04,
									},
								],
							}}
						/>
					</S.flexRowBetween>
				</DashboardBox>
			</Dashboard>
		</>
	);
};

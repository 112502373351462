import { atom } from "recoil";

const AlertAtom = atom({
	key: "isOpenAlert",
	default: false,
});
const ConfirmAtom = atom({
	key: "isOpenConfirm",
	default: false,
});
const ModalAtom = atom({
	key: "isOpenModal",
	default: false,
});
const PopupAtom = atom({
	key: "isOpenPopup",
	default: false,
});

// 로그인 안내
const LoginAtom = atom({
	key: "isOpenLogin",
	default: false,
});

export { AlertAtom, ConfirmAtom, ModalAtom, PopupAtom, LoginAtom };

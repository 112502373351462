// 일반 배송
import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { MenuAtom, UserAtom } from "../Recoil/Atoms/UserState";
import Pagination from "../Components/Pagination";

import * as S from "../Styles/Style";
import Util from "../Store/Util";
import Page from "../Components/Page";
import { ReactComponent as Plus } from "../Images/plus.svg";

interface list {
	idx: number;
	text: string;
	number: number;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const page = searchParams.get("page");
	const [totalItems, setTotalItems] = useState<number>(130);
	const [list, setList] = useState<list[] | null>([
		{ idx: 1, text: "내용", number: 11 },
		{ idx: 2, text: "내용", number: 22 },
	]);

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		/* api 호출 및 데이터(totalItems, books) 저장 */
	}, [page]);

	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<Page
			title="일반 배송"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<select name="searchDtOption" id="searchDtOption">
								<option value="">선택</option>
							</select>
							<input
								type="date"
								name="searchStrDt"
								id="searchStrDt"
							/>
							<p> - </p>
							<input
								type="date"
								name="searchEndDt"
								id="searchEndDt"
							/>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio1"
								defaultChecked
							/>
							<label htmlFor="searchDtRadio1">전체</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio2"
							/>
							<label htmlFor="searchDtRadio2">1주일</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio3"
							/>
							<label htmlFor="searchDtRadio3">1개월</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio4"
							/>
							<label htmlFor="searchDtRadio4">3개월</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio5"
							/>
							<label htmlFor="searchDtRadio5">6개월</label>
						</S.searchRow>
						<S.searchRow>
							<select name="searchOption" id="searchOption">
								<option value="">선택</option>
							</select>
							<input type="text" id="searchWord" />
							<select
								name="searchDetailOption"
								id="searchDetailOption"
							>
								<option value="">선택</option>
							</select>
							<input type="text" id="searchWordDetail" />
							<S.searchButton>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.searchButton color={S.color.primaryVariant}>
								<S.headerText>엑셀 다운로드</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
							>
								<Plus width="2rem" height="2rem" fill="white" />
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<div>
								<h2>분류</h2>
								<ul>
									<li>
										<input
											type="checkbox"
											name=""
											id="filter1_1"
										/>
										<label htmlFor="filter1_1">선택</label>
									</li>
									<li>
										<input
											type="checkbox"
											name=""
											id="filter1_2"
										/>
										<label htmlFor="filter1_2">선택</label>
									</li>
								</ul>
							</div>
						</S.filterArea>
					</S.searchArea>
					{/* 버튼 영역 */}
					<S.asideButtons>
						<button>추가나수정</button>
						<button>팝업을여는</button>
						<button>버튼리스트</button>
					</S.asideButtons>
					{/* 통계 영역 */}
					<S.statsArea>
						<p>
							이것의 통계:{" "}
							<S.headerText>{Util.addComma(123456)}</S.headerText>
						</p>
						<p>
							저것의 통계:{" "}
							<S.headerText>{Util.addComma(0)}</S.headerText>
						</p>
					</S.statsArea>
					{/* 탭 영역 */}
					<S.tabArea>
						<button className="active">
							<S.headerText>탭1</S.headerText>
						</button>
						<button>
							<S.headerText>탭2</S.headerText>
						</button>
					</S.tabArea>
					{/* 테이블 영역 */}
					<div
						style={{
							flexGrow: 1,
							borderBottom: "1px solid",
							borderColor: S.color.gray03,
						}}
					>
						<S.table01>
							<thead>
								<tr>
									<th>제목</th>
									<th>제목</th>
									<th>제목</th>
									<th>제목</th>
									<th>제목</th>
									<th>제목</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{item.idx}</td>
												<td>{item.text}</td>
												<td>{item.number}</td>
												<td>{item.idx}</td>
												<td>{item.text}</td>
												<td>{item.number}</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</div>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={10} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};

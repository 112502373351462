import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const get = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.get(apiUrl + url, {
			params: params,
			headers: {
				Authorization: token,
				"Content-Type": `application/json;charset=UTF-8`,
				Accept: "application/json",
				// 추가
				"Access-Control-Allow-Origin": "https://admin.go-pang.com",
				"Access-Control-Allow-Credentials": true,
			},
		});
	} catch (e) {
		result = e;
	}
	return result;
};
const post = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.post(apiUrl + url, params, {
			headers: {
				Authorization: token,
				"Content-Type": `application/json;charset=UTF-8`,
				Accept: "application/json",
				// 추가
				"Access-Control-Allow-Origin": [
					`https://api.admin.go-pang.com`,
					"https://admin.go-pang.com",
				],
				"Access-Control-Allow-Credentials": true,
			},
		});
	} catch (e) {
		result = e;
	}
	return result;
};
const patch = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.patch(apiUrl + url, params, {
			headers: {
				Authorization: token,
				"Content-Type": `application/json;charset=UTF-8`,
				Accept: "application/json",
				// 추가
				"Access-Control-Allow-Origin": [
					`https://api.admin.go-pang.com`,
					"https://admin.go-pang.com",
				],
				"Access-Control-Allow-Credentials": true,
			},
		});
	} catch (e) {
		result = e;
	}
	return result;
};
export default { get, post, patch };

// 유의사항 수정
import { useState, useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";

export default () => {
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);

	const [comment, setComment] = useState<string>("");

	useEffect(() => {
		user.isLogin && GetData();
	}, [user]);
	// 정보 불러오기
	const GetData = async () => {
		setLoading(true);
		await Api.get("present/presentDescription")
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					setComment(res.data.data.comment);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 수정하기 버튼 클릭 이벤트
	const save = async () => {
		if (
			window.confirm(
				"변경 내역은 상세정보에 일괄 반영됩니다.\n해당 내용을 저장하시겠습니까?"
			)
		) {
			setLoading(true);
			await Api.patch("present/presentDescriptionUpdate", {
				comment: comment,
			})
				.then((res: any) => {
					window.close();
				})
				.catch((error) => {
					console.log(error.response.data, "wwwwww");
				});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>유의사항</S.headerText>
				</h1>
				<S.blank2 />
				<S.textAreaWrap>
					<textarea
						name=""
						id=""
						cols={30}
						rows={10}
						value={comment}
						onChange={(e) => {
							setComment(e.target.value);
						}}
					/>
				</S.textAreaWrap>
				<S.blank2 />
				<S.flexRowCenter>
					<S.filledButton onClick={() => save()}>
						수정하기
					</S.filledButton>
				</S.flexRowCenter>
			</S.popupPage>
		</>
	);
};

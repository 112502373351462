import { atom } from "recoil";

const accessTokenAtom = atom({
	key: "accessTokenAtom",
	default:
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpblR5cGUiOiJhcHBsZSIsIm1tSWR4IjoxLCJsb2dpbkR0IjoiMjAyMzA3MDYxNjI0Mjg4NTciLCJjcmVhdGVBdCI6IjIwMjMwNzExMTU0NjU2MTcxIiwiaWF0IjoxNjg5MDU4MDE2LCJleHAiOjE3MDQ2MTAwMTZ9.JRpex5Au3ccW6wHdRev0sDBckl_Ex_jDt_37pRzpjsU",
});
const LoadingAtom = atom({
	key: "isLoading",
	default: false,
});
const UserAtom = atom({
	key: "UserAtom",
	default: {
		isLogin: false,
		amIdx: 0,
		depart: "-",
		levelCode: 0,
		loginDt: "-",
		name: "-",
		grade: [
			{
				agIdx: "",
				agmIdx: "",
				amIdx: 0,
				grade: 0,
				gradeGroup: "",
			},
		],
	},
});
const MenuAtom = atom({
	key: "MenuAtom",
	default: [
		{
			main: "관리자",
			sub: [{ text: "관리자 목록", link: "/ADMI001001", agmIdx: 1 }],
		},
		{
			main: "회원 관리",
			sub: [
				{ text: "회원 목록", link: "/MEMB001001", agmIdx: 2 },
				{ text: "제재계정 목록", link: "/", agmIdx: 3 },
				// { text: "등급 관리", link: "/", agmIdx: 4 },
				{ text: "탈퇴계정 목록", link: "/", agmIdx: 5 },
				// { text: "휴면계정 목록", link: "/", agmIdx: 6 },
			],
		},
		{
			main: "보물 관리",
			sub: [
				{ text: "보물 목록", link: "/PRST001001", agmIdx: 7 },
				{ text: "보물 상자", link: "/", agmIdx: 8 },
				{ text: "유니크 보물 목록", link: "/PRST003001", agmIdx: 10 },
				{ text: "트레이드 목록", link: "/", agmIdx: 11 },
				{ text: "합성 목록", link: "/", agmIdx: 12 },
			],
		},
		{
			main: "매출 관리",
			sub: [
				{
					text: "관리자 지급/회수 목록",
					link: "/CHPO001001",
					agmIdx: 13,
				},
				{ text: "결제 내역 목록", link: "/", agmIdx: 14 },
			],
		},
		{
			main: "운영 관리",
			sub: [
				{ text: "공지사항", link: "/MANA001001", agmIdx: 15 },
				{ text: "자주 묻는 질문", link: "/", agmIdx: 16 },
				{ text: "1:1 문의", link: "/MANA003001", agmIdx: 17 },
				{ text: "이벤트", link: "/", agmIdx: 18 },
				{ text: "일반 배송", link: "/MANA005001", agmIdx: 19 },
				{ text: "푸시 관리", link: "/", agmIdx: 21 },
			],
		},
	],
});

export { LoadingAtom, UserAtom, accessTokenAtom, MenuAtom };

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
// 이미지
import Chevron from "../Images/chevron.svg";

const Wrapper = styled(S.flexRowCenter)`
	margin: 2rem 0;
	li {
		float: left;
		height: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0.2rem;
	}
	a {
		height: 3rem;
		line-height: 3rem;
		font-size: 1.2rem;
	}
	.page {
		cursor: pointer;
		width: 3rem;
		text-align: center;
		border-radius: 50%;
	}

	.icon,
	.move::after,
	.move::before {
		position: absolute;
		font-size: 20px;
		padding: 0 7px 0px;
	}
	.move {
		position: relative;
		cursor: pointer;
		margin: 0 10px;
	}
	.move a {
		width: 4.5rem;
		display: block;
		z-index: 10;
	}
	.move::before,
	.move::after {
		width: 1.2rem;
		height: 1.2rem;
		background-image: url(${Chevron});
		background-repeat: no-repeat;
		background-position: right;
	}
	.moveStart,
	.movePrev {
		text-align: right;
	}
	.moveStart::before,
	.movePrev::before {
		content: "";
		left: 0;
		transform: rotate(180deg);
	}
	.moveNext::after,
	.moveEnd::after {
		content: "";
		right: 0;
	}
	.moveStart a,
	.moveEnd a {
		color: ${S.color.primary};
		font-family: "IBMPlexSansKRSemiBold";
	}

	.invisible {
		visibility: hidden;
	}

	.active {
		font-weight: 700;
		background: ${S.color.primary};
		color: white;
	}
`;

interface Props {
	totalItems: number;
	itemCountPerPage: number;
	currentPage: number;
}

export default function Pagination({
	totalItems,
	itemCountPerPage,
	currentPage,
}: Props) {
	const totalPages = Math.ceil(totalItems / itemCountPerPage);
	const [start, setStart] = useState(1);
	const noPrev = currentPage === 1;
	const noNext = currentPage >= totalPages;
	const pageCount = 10; // 보여줄 페이지 최대 수량

	useEffect(() => {
		setStart(Math.floor((currentPage - 1) / 10) * 10 + 1);
	}, [currentPage, start]);

	return (
		<Wrapper>
			<ul>
				<li className={`moveStart ${noPrev && "invisible"}`}>
					<Link to={`?page=${1}`}>1</Link>
				</li>
				<li className={`move movePrev ${noPrev && "invisible"}`}>
					<Link to={`?page=${currentPage - 1}`}>이전</Link>
				</li>
				{[...Array(pageCount)].map(
					(a, i) =>
						start + i <= totalPages && (
							<li key={start + i}>
								<Link
									className={`page ${
										currentPage === start + i && "active"
									}`}
									to={`?page=${start + i}`}
								>
									{start + i}
								</Link>
							</li>
						)
				)}
				<li className={`move moveNext ${noNext && "invisible"}`}>
					<Link to={`?page=${currentPage + 1}`}>다음</Link>
				</li>
				<li className={`moveEnd ${noNext && "invisible"}`}>
					<Link to={`?page=${totalPages}`}>{totalPages}</Link>
				</li>
			</ul>
		</Wrapper>
	);
}

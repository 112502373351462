// 보물 일괄 등록
import React, { useState } from "react";
import axios from "axios";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Util from "../Store/Util";

export default () => {
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const apiUrl = process.env.REACT_APP_API_URL;
	const token = localStorage.getItem("token");

	const [excelName, setExcelName] = useState<string>("");
	const [imageName, setImageName] = useState<string>("");

	const upload = async (
		e: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (e.target.files !== null && e.target.files.length > 0) {
			// 파일 첨부인 상태일경우 파일명 출력
			type == "excel" && setExcelName(e.target.files[0].name);
			type == "image" && setImageName(e.target.files[0].name);
		} else {
			//버튼 클릭후 취소(파일 첨부 없을 경우)할때 파일명값 안보이게
			type == "excel" && setExcelName("");
			type == "image" && setImageName("");
		}
	};

	// 등록하기 버튼 클릭 이벤트
	const onSubmit = async (e: any) => {
		e.preventDefault();
		const formData = new FormData();

		formData.append("displayDt", Util.onlyNum(e.target.displayDt.value));
		formData.append("Image", e.target.image.files[0]);
		formData.append("Excel", e.target.excel.files[0]);

		if (
			e.target.image.files[0] == null ||
			e.target.excel.files[0] == undefined
		) {
			alert("파일이 등록되지 않았습니다.");
			return false;
		}

		for (let key of formData.keys()) {
			console.log(key, ":", formData.get(key));
		}

		if (window.confirm("해당 보물을 등록하시겠습니까?")) {
			setLoading(true);
			await axios
				.post(apiUrl + `present/presentExcel`, formData, {
					headers: {
						Authorization: token,
						"content-type": "multipart/form-data",
					},
				})
				.then((res) => {
					if (res.data.error == "-211") {
						var regex = /[^0-9,]/g;
						const Arr: number[] = res.data.message.replace(
							regex,
							""
						);
						alert(`실패 일련번호 - ${Arr}`);
					} else if (res.data.error == "-201") {
						// DB 쿼리 실행 실패
						alert("상품 등록 실패");
					} else if (res.data.error == "-221") {
						alert("데이터가 중복됐습니다.");
					} else if (res.data.error == "-231") {
						alert("이미지 등록에 실패했습니다.");
					} else if (res.data.error == "-401") {
						var regex = /[^0-9,]/g;
						const Arr: number[] = res.data.message.replace(
							regex,
							""
						);
						if (Arr.length > 0) {
							alert(`실패 일련번호 - ${Arr}`);
						} else {
							alert("pmIdx가 없습니다.");
						}
					} else if (res.data.error == "-402") {
						alert("파일 형식이 잘못됐습니다.");
					} else if (res.data.error == "0") {
						window.opener.location.reload();
						window.close();
					} else {
						alert("등록 실패, 관리자에게 문의하세요.");
					}
					console.log(res);
				});
			setLoading(false);
		}
	};

	return (
		<>
			<S.popupPage>
				<form onSubmit={onSubmit}>
					<h1>
						<S.headerText>보물 일괄 등록</S.headerText>
					</h1>
					<S.table02>
						<tbody>
							<tr>
								<th>보물 노출 예약*</th>
							</tr>
							<tr>
								<td>
									<input
										type="datetime-local"
										name="displayDt"
										id="displayDt"
										required
									/>
								</td>
							</tr>
							<tr>
								<th>기본 정보 업로드(.xlsx)*</th>
							</tr>
							<tr>
								<td>
									<S.normalFile>
										<input
											type="file"
											name="excel"
											id="excel"
											accept=".xlsx"
											onChange={(e) => upload(e, "excel")}
										/>
										<label htmlFor="excel">
											<S.headerText>
												파일 선택
											</S.headerText>
										</label>
										<p>
											<S.bodyText>
												{excelName == ""
													? "선택된 파일 없음"
													: excelName}
											</S.bodyText>
										</p>
									</S.normalFile>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<S.table02>
						<tbody>
							<tr>
								<th>이미지 업로드(zip파일)*</th>
							</tr>
							<tr>
								<td>
									<S.normalFile>
										<input
											type="file"
											name="image"
											id="image"
											accept=".zip"
											onChange={(e) => upload(e, "image")}
										/>
										<label htmlFor="image">
											<S.headerText>
												파일 선택
											</S.headerText>
										</label>
										<p>
											<S.bodyText>
												{imageName == ""
													? "선택된 파일 없음"
													: imageName}
											</S.bodyText>
										</p>
									</S.normalFile>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<S.blank2 />
					<S.flexRowCenter>
						<S.filledButton type="submit">등록하기</S.filledButton>
					</S.flexRowCenter>
				</form>
			</S.popupPage>
		</>
	);
};

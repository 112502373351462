// 페이지 탬플릿
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";

import Header from "../Components/Header";

const Container = styled.div`
	width: 98%;
	height: 100%;
	max-width: 130rem;
	margin: auto;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
`;
const Contents = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	& > div {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
`;

interface DefaultType {
	title?: string;
	contents: React.ReactNode;
}

export default ({ title, contents }: PropsWithChildren<DefaultType>) => {
	const navigate = useNavigate();

	return (
		<S.page>
			<Header />
			<Container>
				{title && (
					<S.pageTitle>
						<S.headerText>{title}</S.headerText>
					</S.pageTitle>
				)}
				{/* 검색 영역 */}
				{/* 버튼 영역 */}
				<Contents>{contents}</Contents>
				{/* 페이지네이션 영역 */}
			</Container>
		</S.page>
	);
};

// 관리자 정보 수정
import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";

const GradeTable = styled.table`
	width: 100%;
	padding: 1rem;
	thead {
		tr {
		}
		th {
			width: calc(100% / 4);
			text-align: center;
			border-bottom: 1px solid ${S.color.gray03};
			padding: 1rem;
			span {
				font-size: 1.3rem;
				color: ${S.color.gray04};
			}
			label {
				display: block;
				margin-left: 0.5rem;
			}
		}
	}
	td {
		text-align: center;
	}
	th,
	td {
		font-size: 1.2rem;
		padding: 1.2rem 0;
	}
	tr {
		border-bottom: 1px dashed ${S.color.gray02};
	}
	tr:last-child {
		border-bottom: 1px solid ${S.color.gray03};
	}
	tr.main {
		background-color: ${S.color.gray01};
		border-top: 1px solid ${S.color.gray03};
		border-bottom: 1px solid ${S.color.gray02};
	}
	tr.main th {
		padding-left: 1rem;
		text-align: left;
	}
	tr.sub th {
		text-align: left;
		padding-left: 2rem;
		font-family: "IBMPlexSansKR";
		font-weight: unset;
	}
`;

interface info {
	amIdx: number;
	depart: string;
	id: string;
	grade: [];
	grantYn: number;
	joinDt: string;
	name: string;
}

export default () => {
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [adminInfo, setAdminInfo] = useState<info>();
	const [originalMenu, setOriginalMenu] = useState<any>([]);
	const [menu, setMenu] = useState<any>([]);
	const agmIdxList = useRef<number[]>([]);
	// 권한 이름 설정
	let [gradeType, setGradeType] = useState<number>(0);
	let [mainGradeType, setMainGradeType] = useState<number[]>([]);
	const [searchParams] = useSearchParams();
	const amIdx = searchParams.get("amIdx");
	const [depart, setDepart] = useState<string>("");
	const [grantYn, setGrantYn] = useState<number>(0);

	// 관리자 정보 불러오기
	const getList = async () => {
		setLoading(true);
		let grade: { agmIdx: number; grade: number }[] = [];
		await Api.get("manager/manager", { amIdx: amIdx }).then((res: any) => {
			const data = res.data.data;
			setDepart(data.depart);
			setGrantYn(data.grantYn);
			setAdminInfo(data);
			grade = data.grade;
		});
		// 메뉴 리스트 불러오기
		Api.get("manager/gradeList").then((res: any) => {
			const data = res.data.data;
			console.log(data);
			data.map((item: any) => {
				agmIdxList.current.push(item.agmIdx);
			});

			const groupValues = data.reduce((acc: any, current: any) => {
				acc[current.gradeGroup] = acc[current.gradeGroup] || [];
				acc[current.gradeGroup].push({
					text: current.gradeName,
					agmIdx: current.agmIdx,
					grade: grade.find((el) => el.agmIdx == current.agmIdx)
						? grade.find((el) => el.agmIdx == current.agmIdx)?.grade
						: -1,
				});
				return acc;
			}, {});

			// 위에서 만든 객체를 key로 돌려서 새로운 객체 return
			const groups = Object.keys(groupValues).map((key) => {
				return { main: key, sub: groupValues[key] };
			});
			handleMainGradeType(groups);
			handleGradeType(groups);
			setOriginalMenu(groups);
			setMenu(groups);
		});
		setLoading(false);
	};
	useEffect(() => {
		getList();
	}, []);

	// 개별 라디오버튼 클릭 시
	const handleSingleRadio = (agmIdx: number, grade: number) => {
		let changedList = menu.map((element: any) => {
			return {
				...element,
				sub: element.sub.map((el: any, index: number) => {
					return {
						...el,
						grade: el.agmIdx == agmIdx ? grade : el.grade,
					};
				}),
			};
		});
		setMenu(changedList);
		handleMainGradeType(changedList);
		handleGradeType(changedList);
	};
	// 전체 선택 클릭 시
	const handleAllRadio = (grade: number) => {
		let changedList = menu.map((element: any) => {
			return {
				...element,
				sub: element.sub.map((el: any, index: number) => {
					return { ...el, grade: grade };
				}),
			};
		});
		setMenu(changedList);
		handleMainGradeType(changedList);
		handleGradeType(changedList);
	};
	// 전체 권한 등급 책정
	const handleGradeType = (changedList: any) => {
		let newNum = -1;
		changedList.map((element: any) => {
			element.sub.map((el: any, index: number) => {
				if (el.grade >= newNum) {
					newNum = el.grade;
				}
			});
		});
		setGradeType(newNum > 2 ? 2 : newNum);
	};
	// 대메뉴 선택 클릭 시
	const handleMainRadio = (main: string, grade: number) => {
		let changedList = menu.map((element: any) => {
			if (element.main == main) {
				return {
					...element,
					sub: element.sub.map((el: any, index: number) => {
						return { ...el, grade: grade };
					}),
				};
			} else {
				return { ...element };
			}
		});
		setMenu(changedList);
		handleMainGradeType(changedList);
		handleGradeType(changedList);
	};
	// 대메뉴별 권한 등급 책정
	const handleMainGradeType = (changedList: any) => {
		let newArr: number[] = [];
		changedList.map((element: any) => {
			let newNum = -1;
			element.sub.map((el: any, index: number) => {
				if (el.grade >= newNum) {
					newNum = el.grade;
				}
			});
			newArr.push(newNum);
		});
		setMainGradeType(newArr);
	};

	// 저장하기 버튼 클릭
	const updateInfo = () => {
		let changedGrade: { agmIdx: number; grade: number }[] = [];
		let originalgrade: { agmIdx: number; grade: number }[] = [];

		menu.map((element: any) => {
			return {
				...element,
				sub: element.sub.map((el: any) => {
					changedGrade.push({ agmIdx: el.agmIdx, grade: el.grade });
				}),
			};
		});
		originalMenu.map((element: any) => {
			return {
				...element,
				sub: element.sub.map((el: any) => {
					originalgrade.push({ agmIdx: el.agmIdx, grade: el.grade });
				}),
			};
		});

		let original = JSON.stringify(originalgrade);
		let grade = changedGrade.filter((el: any) => {
			return !original.includes(JSON.stringify(el));
		});

		Api.post("manager/managerUpdate", {
			amIdx: amIdx,
			grade: grade, // 등급코드 : 0:없음 1:뷰어 2:뷰어편집 콤마구분 순서대로
			grant: adminInfo?.grantYn == grantYn ? null : grantYn, // 승인 미승인 1:승인 0:미승인 -1:탈퇴
			depart: adminInfo?.depart == depart ? null : depart,
		}).then((res: any) => {
			if (res.data) {
				if (res.data.statusCode == 200) {
					window.opener.location.reload();
					window.close();
				}
			} else {
				if (res.response.data.error == -203) {
					alert("수정된 내용이 없습니다.");
				} else if (res.response.data.error == -201) {
					alert("권한이 없습니다.");
				}
			}
		});
	};

	return (
		<>
			<S.popupPage>
				<h1>
					<S.headerText>관리자 정보 수정</S.headerText>
				</h1>
				<form action="">
					<h2>기본 정보</h2>
					<S.table02>
						<tbody>
							<tr>
								<th>가입일</th>
								<th>관리자 번호</th>
							</tr>
							<tr>
								<td>
									<input
										type="text"
										id="joinDt"
										readOnly
										value={
											adminInfo
												? Util.dateFormat(
														adminInfo?.joinDt,
														"date"
												  )
												: "-"
										}
									/>
								</td>
								<td>
									<input
										type="text"
										id="amIdx"
										readOnly
										value={
											adminInfo ? adminInfo.amIdx : "-"
										}
									/>
								</td>
							</tr>
							<tr>
								<th>이름</th>
								<th>부서명</th>
							</tr>
							<tr>
								<td>
									<input
										type="text"
										id="name"
										autoComplete="off"
										readOnly
										value={adminInfo ? adminInfo.name : "-"}
									/>
								</td>
								<td>
									<select
										name=""
										id="depart"
										value={depart}
										onChange={(e) =>
											setDepart(e.target.value)
										}
									>
										<option value="보물선 운영팀">
											보물선 운영팀
										</option>
										<option value="경영지원팀">
											경영지원팀
										</option>
										<option value="개발팀">개발팀</option>
										<option value="기획팀">기획팀</option>
										<option value="디자인팀">
											디자인팀
										</option>
										<option value="마케팅팀">
											마케팅팀
										</option>
										<option value="이사로 운영팀">
											이사로 운영팀
										</option>
										<option value="인사팀">인사팀</option>
										<option value="기타">기타</option>
									</select>
								</td>
							</tr>
							<tr>
								<th>활동 구분</th>
								<th>아이디</th>
							</tr>
							<tr>
								<td>
									<S.normalRadioArea>
										<S.normalRadio>
											<input
												type="radio"
												name="approval"
												id="approval0"
												value={0}
												checked={grantYn == 0}
												onChange={(e) =>
													setGrantYn(
														Number(e.target.value)
													)
												}
												disabled={
													adminInfo?.grantYn !== 0
												}
											/>
											<label htmlFor="approval0" />
										</S.normalRadio>
										<S.normalLabel htmlFor="approval0">
											미승인
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="approval"
												id="approval1"
												value={1}
												checked={grantYn == 1}
												onChange={(e) =>
													setGrantYn(
														Number(e.target.value)
													)
												}
											/>
											<label htmlFor="approval1" />
										</S.normalRadio>
										<S.normalLabel htmlFor="approval1">
											승인
										</S.normalLabel>
										<S.normalRadio>
											<input
												type="radio"
												name="approval"
												id="approval2"
												value={-1}
												checked={grantYn == -1}
												onChange={(e) =>
													setGrantYn(
														Number(e.target.value)
													)
												}
											/>
											<label htmlFor="approval2" />
										</S.normalRadio>
										<S.normalLabel htmlFor="approval2">
											탈퇴
										</S.normalLabel>
									</S.normalRadioArea>
								</td>
								<td>
									<input
										type="text"
										id="id"
										autoComplete="off"
										readOnly
										value={adminInfo ? adminInfo.id : "-"}
									/>
								</td>
							</tr>
						</tbody>
					</S.table02>
					<h2>권한등급</h2>
					<S.table02>
						<tbody>
							<tr>
								<td colSpan={3}>
									<div>
										<GradeTable>
											<thead>
												<tr>
													<th>
														<S.headerText>
															대메뉴/소메뉴
														</S.headerText>
													</th>
													<th>
														<S.normalRadio>
															<S.headerText>
																권한 제외
															</S.headerText>
															<input
																type="radio"
																name="grade"
																id="except"
																checked={
																	gradeType ==
																	0
																}
																onChange={() =>
																	handleAllRadio(
																		0
																	)
																}
																onClick={() =>
																	handleAllRadio(
																		0
																	)
																}
																disabled={
																	grantYn !==
																	1
																}
															/>
															<label htmlFor="except" />
														</S.normalRadio>
													</th>
													<th>
														<S.normalRadio>
															<S.headerText>
																뷰어
															</S.headerText>
															<input
																type="radio"
																name="grade"
																id="viewer"
																checked={
																	gradeType ==
																	1
																}
																onChange={() =>
																	handleAllRadio(
																		1
																	)
																}
																onClick={() =>
																	handleAllRadio(
																		1
																	)
																}
																disabled={
																	grantYn !==
																	1
																}
															/>
															<label htmlFor="viewer" />
														</S.normalRadio>
													</th>
													<th>
														<S.normalRadio>
															<S.headerText>
																뷰어+편집
															</S.headerText>
															<input
																type="radio"
																name="grade"
																id="editor"
																checked={
																	gradeType ==
																	2
																}
																onChange={() =>
																	handleAllRadio(
																		2
																	)
																}
																onClick={() =>
																	handleAllRadio(
																		2
																	)
																}
																disabled={
																	grantYn !==
																	1
																}
															/>
															<label htmlFor="editor" />
														</S.normalRadio>
													</th>
												</tr>
											</thead>
											<tbody>
												{menu.map((m: any, mi: any) => {
													return (
														<React.Fragment
															key={mi}
														>
															<tr className="main">
																<th>
																	{m.main}
																</th>
																<td>
																	<S.normalRadio>
																		<input
																			type="radio"
																			name={
																				"main" +
																				mi
																			}
																			id={
																				"main" +
																				mi +
																				"_1"
																			}
																			checked={
																				mainGradeType[
																					mi
																				] ==
																				0
																			}
																			onClick={() =>
																				handleMainRadio(
																					m.main,
																					0
																				)
																			}
																			onChange={() =>
																				handleMainRadio(
																					m.main,
																					0
																				)
																			}
																			disabled={
																				grantYn !==
																				1
																			}
																		/>
																		<label
																			htmlFor={
																				"main" +
																				mi +
																				"_1"
																			}
																		/>
																	</S.normalRadio>
																</td>
																<td>
																	<S.normalRadio>
																		<input
																			type="radio"
																			name={
																				"main" +
																				mi
																			}
																			id={
																				"main" +
																				mi +
																				"_2"
																			}
																			checked={
																				mainGradeType[
																					mi
																				] ==
																				1
																			}
																			onClick={() =>
																				handleMainRadio(
																					m.main,
																					1
																				)
																			}
																			onChange={() =>
																				handleMainRadio(
																					m.main,
																					1
																				)
																			}
																			disabled={
																				grantYn !==
																				1
																			}
																		/>
																		<label
																			htmlFor={
																				"main" +
																				mi +
																				"_2"
																			}
																		/>
																	</S.normalRadio>
																</td>
																<td>
																	<S.normalRadio>
																		<input
																			type="radio"
																			name={
																				"main" +
																				mi
																			}
																			id={
																				"main" +
																				mi +
																				"_3"
																			}
																			checked={
																				mainGradeType[
																					mi
																				] ==
																				2
																			}
																			onClick={() =>
																				handleMainRadio(
																					m.main,
																					2
																				)
																			}
																			onChange={() =>
																				handleMainRadio(
																					m.main,
																					2
																				)
																			}
																			disabled={
																				grantYn !==
																				1
																			}
																		/>
																		<label
																			htmlFor={
																				"main" +
																				mi +
																				"_3"
																			}
																		/>
																	</S.normalRadio>
																</td>
															</tr>
															{m.sub.map(
																(
																	s: any,
																	si: number
																) => {
																	return (
																		<tr
																			key={
																				si
																			}
																			className="sub"
																		>
																			<th>
																				{
																					s.text
																				}
																			</th>
																			<td>
																				<S.normalRadio>
																					<input
																						type="radio"
																						name={
																							s.agmIdx
																						}
																						id={
																							s.agmIdx +
																							"_1"
																						}
																						checked={
																							s.grade ==
																							0
																						}
																						onChange={() =>
																							handleSingleRadio(
																								s.agmIdx,
																								0
																							)
																						}
																						disabled={
																							grantYn !==
																							1
																						}
																					/>
																					<label
																						htmlFor={
																							s.agmIdx +
																							"_1"
																						}
																					/>
																				</S.normalRadio>
																			</td>
																			<td>
																				<S.normalRadio>
																					<input
																						type="radio"
																						name={
																							s.agmIdx
																						}
																						id={
																							s.agmIdx +
																							"_2"
																						}
																						checked={
																							s.grade ==
																							1
																						}
																						onChange={() =>
																							handleSingleRadio(
																								s.agmIdx,
																								1
																							)
																						}
																						disabled={
																							grantYn !==
																							1
																						}
																					/>
																					<label
																						htmlFor={
																							s.agmIdx +
																							"_2"
																						}
																					/>
																				</S.normalRadio>
																			</td>
																			<td>
																				<S.normalRadio>
																					<input
																						type="radio"
																						name={
																							s.agmIdx
																						}
																						id={
																							s.agmIdx +
																							"_3"
																						}
																						checked={
																							s.grade ==
																							2
																						}
																						onChange={() =>
																							handleSingleRadio(
																								s.agmIdx,
																								2
																							)
																						}
																						disabled={
																							grantYn !==
																							1
																						}
																					/>
																					<label
																						htmlFor={
																							s.agmIdx +
																							"_3"
																						}
																					/>
																				</S.normalRadio>
																			</td>
																		</tr>
																	);
																}
															)}
														</React.Fragment>
													);
												})}
											</tbody>
										</GradeTable>
									</div>
								</td>
							</tr>
						</tbody>
					</S.table02>
				</form>
				<S.blank2 />
				<S.flexRowCenter>
					<S.filledButton onClick={() => updateInfo()}>
						저장하기
					</S.filledButton>
				</S.flexRowCenter>
			</S.popupPage>
		</>
	);
};

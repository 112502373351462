import styled from "styled-components";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";

import { ReactComponent as Logo } from "../Images/logo.svg";

const TopBar = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem 0.2rem;
`;
const UserInfo = styled.ul`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	li {
		padding: 0 0.8rem;
		font-size: 1.2rem;
		border-right: 1px solid ${S.color.gray02};
	}
	li:last-child {
		border-right: 0;
	}
`;
const GlobalNav = styled(S.flexRowCenter)`
	background-color: ${S.color.primary};
`;
const MainMenu = styled.ul`
	width: 90%;
	max-width: 1300px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	& > li {
		width: 100%;
		text-align: center;
		padding: 1rem;
		position: relative;
		span {
			color: white;
			font-size: 1.4rem;
		}
		&:hover {
			background-color: white;
			span {
				color: ${S.color.primaryVariant};
			}
			& > ul {
				height: auto;
				border: 1px solid ${S.color.gray02};
				border-top: 0;
			}
		}
	}
`;
const SubMenu = styled.ul`
	width: 100%;
	height: 0;
	overflow: hidden;
	background-color: white;
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translate(-50%);
	z-index: 10;
	a {
		height: 100%;
		width: 100%;
		font-size: 1.4rem;
		display: inline-block;
		padding: 1.5rem;
	}
`;

export default () => {
	const user = useRecoilValue(UserAtom);
	const [isOpenLogin, setOpenLogin] = useRecoilState<boolean>(LoginAtom);
	const menu = useRecoilValue(MenuAtom);

	const gradeCheck = (agmIdx: number) => {
		if (agmIdx == 1) {
			return true;
		}
		if (user.grade.length > 0) {
			const same = user.grade.filter(
				(item) => Number(item?.agmIdx) == agmIdx
			);
			if (same.length > 0) {
				return same[0].grade >= 1 ? true : false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};
	return (
		<>
			<TopBar>
				<a href="/">
					{/* <S.mainTitle color={S.color.primary} fontSize="1.6rem">
						GOPANG
					</S.mainTitle> */}
					<Logo width="8rem" height="100%" />
				</a>
				<S.flexRowCenter>
					<UserInfo>
						<li>{user.depart}</li>
						<li>{user.name}</li>
					</UserInfo>
					<button
						onClick={() => {
							localStorage.removeItem("token");
							setOpenLogin(!isOpenLogin);
						}}
					>
						로그아웃
					</button>
				</S.flexRowCenter>
			</TopBar>
			{/* 네비게이션 */}
			<GlobalNav>
				<MainMenu>
					{menu.map((m, i) => {
						return (
							<li key={i}>
								<S.headerText>{m.main}</S.headerText>
								<SubMenu>
									{m.sub.map((s, i) => {
										return (
											<li key={i}>
												{gradeCheck(s.agmIdx) ? (
													<Link
														reloadDocument
														to={s.link}
													>
														{s.text}
													</Link>
												) : (
													<a
														onClick={() => {
															alert(
																"권한이 없습니다. 마스터에게 문의해 주세요."
															);
														}}
													>
														{s.text}
													</a>
												)}
											</li>
										);
									})}
								</SubMenu>
							</li>
						);
					})}
				</MainMenu>
			</GlobalNav>
		</>
	);
};

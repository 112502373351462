// 1:1 문의
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	bqIdx: string;
	answeredType: string;
	bq_file1: string;
	bq_file2: string;
	bq_file3: string;
	bq_questionSubject: string;
	bq_questionText: string;
	categoryCode: string;
	categoryName: string;
	displayYn: number;
	email: string;
	mmIdx: number;
	modDt: string;
	name: string;
	nick: string;
	progressCode: string;
	regDt: string;
	smsSendYn: number;
	tel: string;
	viewCount: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 체크된 아이템 담을 배열
	const [checkedItem, setCheckedItem] = useState<string[]>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const now = new Date(Util.getNow());
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const [displayYn, setDisplayYn] = useState<string[]>([]);
	const [progressCode, setProgressCode] = useState<string[]>([]);
	const [categoryCode, setCategoryCode] = useState<string[]>([]);

	const filterList = [
		{
			title: "분류",
			name: "categoryCode",
			items: [
				{ text: "이용", value: 1 },
				{ text: "결제", value: 2 },
				{ text: "배송", value: 4 },
				{ text: "회원", value: 7 },
				{ text: "이벤트", value: 8 },
				{ text: "오류제보", value: 99 },
				{ text: "기타", value: 6 },
			],
			option: categoryCode,
			setOption: setCategoryCode,
		},
		{
			title: "답변 상태",
			name: "progressCode",
			items: [
				{ text: "답변 전", value: 0 },
				{ text: "답변 중", value: 2 },
				{ text: "답변 완료", value: 1 },
			],
			option: progressCode,
			setOption: setProgressCode,
		},
		{
			title: "노출 여부",
			name: "displayYn",
			items: [
				{ text: "노출", value: 1 },
				{ text: "미노출(삭제)", value: 0 },
			],
			option: displayYn,
			setOption: setDisplayYn,
		},
	];
	// 1페이지로 이동되면 리셋
	const pageResset = () => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [user, page]);
	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		await Api.get("board/qna/list", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				console.log(res);
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					setList(res.data.data.qnaList);
					setTotalItems(res.data.data.qnaListCnt);
				}
			})
			.catch((error) => {
				console.log(error, "wwwwww");
			});
		setLoading(false);
	};
	// 노출여부
	const displayYnText = (displayYn: number) => {
		switch (displayYn) {
			case 1:
				return "노출";
			case 0:
				return "미노출";
			default:
				return "-";
		}
	};
	// 답변 상태
	const progressCodeText = (progressCode: string) => {
		switch (progressCode) {
			case "0":
				return "답변 전";
			case "1":
				return "답변 완료";
			case "2":
				return "답변 중";
			default:
				return "-";
		}
	};

	// 체크박스 단일 선택
	const handleSingleCheck = (checked: boolean, bqIdx: string) => {
		if (checked) {
			// 단일 선택 시 체크된 아이템을 배열에 추가
			setCheckedItem((prev) => [...prev, bqIdx]);
		} else {
			// 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
			setCheckedItem(checkedItem.filter((el) => el !== bqIdx));
		}
	};
	// 체크박스 전체 선택
	const handleAllCheck = (checked: boolean) => {
		if (checked) {
			// 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
			const newArray: string[] = [];
			list?.forEach((el) => newArray.push(el.bqIdx));
			setCheckedItem(newArray);
		} else {
			// 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
			setCheckedItem([]);
		}
	};
	// 선택 노출/미노출
	const displatUadate = async (displayYn: number) => {
		if (
			window.confirm(
				`해당 문의를 ${
					displayYn == 1 ? "노출" : "미노출(삭제)"
				} 하시겠습니까?`
			)
		) {
			setLoading(true);
			await Api.patch("board/qnaDisplayUpdate", {
				bqIdx: checkedItem.join(),
				displayYn: displayYn,
				// useYn: "사용여부",
			})
				.then((res: any) => {
					console.log("bqIdx : ", checkedItem.join());
					console.log("displayYn : ", displayYn);
					console.log(res);
					if (res.data == null) {
						const code = res.response.status;
						if (code == 401) {
							setOpenLogin(true);
						}
					} else {
						console.log(res.data.statusCode);
						if (res.data.statusCode == 200) {
							alert("완료되었습니다.");
							window.location.reload();
						}
					}
				})
				.catch((error) => {
					console.log(error.response.data, "wwwwww");
				});
			setLoading(false);
		}
	};
	// 1:1문의 상세 팝업 오픈
	const openDetailPopup = (bqIdx: string) => {
		window.open(
			"/MANA003002?bqIdx=" + bqIdx,
			"_blank",
			"width=700, height=850, left=100, top=100"
		);
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setDisplayYn([]);
		setCategoryCode([]);
		setProgressCode([]);
	};
	// 필터 적용
	const filter = () => {
		console.log(displayYn);
		filterOptionsRef.current = {
			displayYn: displayYn.length >= 2 ? null : displayYn,
			categoryCode: categoryCode.join(),
			progressCode: progressCode.join(),
		};
		pageResset();
		setIsApplied(
			displayYn.length > 0 ||
				categoryCode.length > 0 ||
				progressCode.length > 0
		);
	};
	// 권한 확인(뷰어 제한) 후 액션
	function viewerCheck(action: () => void) {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	}
	return (
		<>
			<Page
				title="1:1 문의"
				contents={
					<div>
						{/* 검색 영역 */}
						<S.searchArea>
							<S.searchRow>
								<input
									type="date"
									name="searchStrDt"
									id="searchStrDt"
									value={searchStrDt}
									onChange={(e) => {
										setSearchStrDt(e.target.value);
									}}
								/>
								<p> - </p>
								<input
									type="date"
									name="searchEndDt"
									id="searchEndDt"
									value={searchEndDt}
									onChange={(e) => {
										setSearchEndDt(e.target.value);
									}}
								/>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio1"
									value={""}
									defaultChecked
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio1">전체</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio2"
									value={7}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio2">1주일</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio3"
									value={30}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio3">1개월</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio4"
									value={90}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio4">3개월</label>
							</S.searchRow>
							<S.searchRow>
								<select
									name="searchKey"
									id="searchKey"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchKey: e.target.value,
										})
									}
								>
									<option value="">= 선 택 =</option>
									<option value="3">회원번호</option>
									<option value="4">닉네임</option>
									<option value="6">이름</option>
									<option value="0">제목+내용</option>
									<option value="5">문의번호</option>
								</select>
								<input
									type="text"
									id="searchWord"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchWord: e.target.value,
										})
									}
								/>
								<S.searchButton onClick={() => search()}>
									<S.headerText>검색하기</S.headerText>
								</S.searchButton>
								<S.searchButton
									onClick={() => {
										viewerCheck(() => {
											if (
												window.confirm(
													"엑셀을 다운 받으시겠습니까?"
												)
											) {
												alert("엑셀다운로드 개발 전");
											}
										});
									}}
									color={S.color.primaryVariant}
								>
									<S.headerText>엑셀 다운로드</S.headerText>
								</S.searchButton>
								<S.filterButton
									onClick={() => {
										setOpenFilter(!isOpenFilter);
									}}
									className={`${isApplied && "applied"}`}
								>
									{isOpenFilter ? (
										<Minus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									) : (
										<Plus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									)}
								</S.filterButton>
							</S.searchRow>
							{/* 필터영역 */}
							<S.filterArea
								className={`${isOpenFilter && "expend"}`}
							>
								<S.filterWrap>
									{filterList.map(
										(filter: any, fi: number) => {
											return (
												<li key={fi}>
													<h2>{filter.title}</h2>
													<ul>
														<Filter
															fi={fi}
															filter={filter} // 필터 아이템 리스트
															option={
																filter.option
															}
															setOption={
																filter.setOption
															}
														/>
													</ul>
												</li>
											);
										}
									)}
								</S.filterWrap>
								<S.flexRowEnd>
									<button onClick={() => filter()}>
										적용
									</button>
									<button onClick={() => filterRefresh()}>
										전체 해제
									</button>
								</S.flexRowEnd>
							</S.filterArea>
						</S.searchArea>
						{/* 버튼 영역 */}
						<S.asideFilledButtons>
							<button
								onClick={() => {
									viewerCheck(() => displatUadate(1));
								}}
								disabled={checkedItem.length <= 0}
							>
								선택 노출
							</button>
							<button
								onClick={() => {
									viewerCheck(() => displatUadate(0));
								}}
								disabled={checkedItem.length <= 0}
							>
								선택 미노출
							</button>
						</S.asideFilledButtons>
						{/* 테이블 영역 */}
						<S.table01Wrap>
							<S.table01>
								<thead>
									<tr>
										<th>
											<S.normalCheckbox>
												<input
													type="checkbox"
													name=""
													id="checkAll"
													checked={
														checkedItem.length ===
														list?.length
													}
													onChange={(e) =>
														handleAllCheck(
															e.target.checked
														)
													}
												/>
												<label htmlFor="checkAll" />
											</S.normalCheckbox>
										</th>
										<th>번호</th>
										<th>분류</th>
										<th>회원 번호</th>
										<th>닉네임</th>
										<th>제목</th>
										<th>등록 일시</th>
										<th>답변 상태</th>
										<th>답변 일시</th>
										<th>노출 여부</th>
										<th>상세정보</th>
									</tr>
								</thead>
								<tbody>
									{list && list.length > 0 ? (
										<>
											{list.map((item, i) => (
												<tr
													key={i}
													className={
														checkedItem.includes(
															item.bqIdx
														)
															? "checked"
															: ""
													}
												>
													<td>
														<S.normalCheckbox>
															<input
																type="checkbox"
																name=""
																id={`check${item.bqIdx}`}
																checked={checkedItem.includes(
																	item.bqIdx
																)}
																onChange={(e) =>
																	handleSingleCheck(
																		e.target
																			.checked,
																		item.bqIdx
																	)
																}
															/>
															<label
																htmlFor={`check${item.bqIdx}`}
															/>
														</S.normalCheckbox>
													</td>
													<td>{item.bqIdx}</td>
													<td>{item.categoryName}</td>
													<td>{item.mmIdx}</td>
													<td>{item.nick}</td>
													<td>
														{
															item.bq_questionSubject
														}
													</td>
													<td>
														{Util.dateFormat(
															item.regDt
														)}
													</td>
													<td>
														{progressCodeText(
															item.progressCode
														)}
													</td>
													<td>
														{Util.dateFormat(
															item.modDt
														)}
													</td>
													<td>
														{displayYnText(
															item.displayYn
														)}
													</td>
													<td>
														<S.tableButton
															onClick={() =>
																openDetailPopup(
																	item.bqIdx
																)
															}
														>
															<S.headerText>
																자세히 보기
															</S.headerText>
														</S.tableButton>
													</td>
												</tr>
											))}
										</>
									) : (
										<tr>
											<td
												colSpan={999}
												style={{ textAlign: "center" }}
											>
												데이터가 없습니다.
											</td>
										</tr>
									)}
								</tbody>
							</S.table01>
						</S.table01Wrap>
						{/* 페이지네이션 영역 */}
						<Pagination
							totalItems={totalItems} // 데이터 총 개수
							currentPage={
								page && parseInt(page) > 0 ? parseInt(page) : 1
							} // 현재 페이지
							itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
						/>
					</div>
				}
			/>
		</>
	);
};

// 유니크 보물 목록
import { useState, useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	categoryName: string;
	gradeCode: string;
	displayPrice: number;
	getChannelName: string;
	mmIdx: number;
	name: string;
	nick: string;
	pmIdx: string;
	pumIdx: number;
	realPrice: number;
	regDt: string;
	stateName: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const now = new Date(Util.getNow());
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [categoryCode, setCategoryCode] = useState<string[]>([]);
	const [getChannelCode, setGetChannelCode] = useState<string[]>([]);
	const [stateCode, setStateCode] = useState<string[]>([]);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const filterList = [
		{
			title: "보물 분류",
			name: "categoryCode",
			items: [
				{ text: "가구/인테리어", value: 1 },
				{ text: "스포츠/레저", value: 2 },
				{ text: "기프티콘", value: 3 },
				{ text: "식품", value: 4 },
				{ text: "디지털/가전", value: 5 },
				{ text: "장난감/완구", value: 6 },
				{ text: "명품", value: 7 },
				{ text: "패션잡화", value: 8 },
				{ text: "생활/건강", value: 9 },
				{ text: "화장품/미용", value: 10 },
				{ text: "지류상품권", value: 11 },
			],
			option: categoryCode,
			setOption: setCategoryCode,
		},
		{
			title: "획득 방식",
			name: "getChannelCode",
			items: [
				{ text: "골드상자 오픈", value: 0 },
				{ text: "무료상자 오픈", value: 1 },
				{ text: "합성", value: 4 },
				{ text: "이벤트", value: 3 },
				{ text: "트레이드", value: 2 },
			],
			option: getChannelCode,
			setOption: setGetChannelCode,
		},
		{
			title: "보물 상태",
			name: "stateCode",
			items: [
				{ text: "보관", value: "00" },
				{ text: "배송 요청", value: "40" },
				{ text: "배송 준비", value: "45" },
				{ text: "배송 중", value: "41" },
				{ text: "배송 완료", value: "43" },
				{ text: "합성 재료", value: "30" },
				{ text: "트레이드 등록", value: 0 },
				{ text: "트레이드 중", value: 0 },
				{ text: "트레이드 완료", value: 0 },
			],
			option: stateCode,
			setOption: setStateCode,
		},
	];

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);

	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		console.log(searchOptionsRef.current);
		await Api.get("present/UniquePresentList", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.datas);
					setTotalItems(res.data.data.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setCategoryCode([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = {
			categoryCode: categoryCode.join(),
			getChannelCode: getChannelCode.join(),
			stateCode: stateCode.join(),
		};
		pageResset();
		setIsApplied(categoryCode.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<Page
			title="유니크 보물 목록"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<input
								type="date"
								name="searchStrDt"
								id="searchStrDt"
								value={searchStrDt}
								onChange={(e) => {
									setSearchStrDt(e.target.value);
								}}
							/>
							<p> - </p>
							<input
								type="date"
								name="searchEndDt"
								id="searchEndDt"
								value={searchEndDt}
								onChange={(e) => {
									setSearchEndDt(e.target.value);
								}}
							/>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio1"
								value={""}
								defaultChecked
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio1">전체</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio2"
								value={7}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio2">1주일</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio3"
								value={30}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio3">1개월</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio4"
								value={90}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio4">3개월</label>
						</S.searchRow>
						<S.searchRow>
							<select
								name="searchKey"
								id="searchKey"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchKey: e.target.value,
									})
								}
							>
								<option value="">= 선 택 =</option>
								<option value="1">유니크 보물 번호</option>
								<option value="2">보물번호</option>
								<option value="5">보물명</option>
								<option value="3">회원번호</option>
								<option value="4">닉네임</option>
							</select>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.searchButton
								onClick={() => {
									viewerCheck(() => {
										if (
											window.confirm(
												"엑셀을 다운 받으시겠습니까?"
											)
										) {
											alert("엑셀다운로드 개발 전");
										}
									});
								}}
								color={S.color.primaryVariant}
							>
								<S.headerText>엑셀 다운로드</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
								className={`${isApplied && "applied"}`}
							>
								{isOpenFilter ? (
									<Minus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								) : (
									<Plus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								)}
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<S.filterWrap>
								{filterList.map((filter: any, fi: number) => {
									return (
										<li key={fi}>
											<h2>{filter.title}</h2>
											<ul>
												<Filter
													fi={fi}
													filter={filter} // 필터 아이템 리스트
													option={filter.option}
													setOption={filter.setOption}
												/>
											</ul>
										</li>
									);
								})}
							</S.filterWrap>
							<S.flexRowEnd>
								<button onClick={() => filter()}>적용</button>
								<button onClick={() => filterRefresh()}>
									전체 해제
								</button>
							</S.flexRowEnd>
						</S.filterArea>
					</S.searchArea>
					{/* 통계 영역 */}
					<S.statsArea>
						<p>
							총
							<S.headerText>
								{Util.addComma(totalItems)}
							</S.headerText>
							건
						</p>
					</S.statsArea>
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th
										className="sort down"
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 1,
											});
											pageResset();
										}}
									>
										유니크 보물번호
									</th>
									<th>보물 번호</th>
									<th>보물명</th>
									<th>보물 분류</th>
									<th>보물 등급</th>
									<th
										className="sort "
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 2,
											});
											pageResset();
										}}
									>
										정가(원)
									</th>
									<th
										className="sort "
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 3,
											});
											pageResset();
										}}
									>
										실구매가(원)
									</th>
									<th>회원 번호</th>
									<th>회원 닉네임</th>
									<th>획득 일시</th>
									<th>획득 방식</th>
									<th>보물 상태</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{item.pumIdx}</td>
												<td
													className="Clickable"
													onClick={() => {
														window.open(
															"/PRST001003?pmIdx=" +
																item.pmIdx,
															"_blank",
															"width=600, height=800, left=100, top=100"
														);
													}}
												>
													{item.pmIdx}
												</td>
												<td>{item.name}</td>
												<td>{item.categoryName}</td>
												<td>{item.gradeCode}</td>
												<td>
													{Util.addComma(
														Number(
															item.displayPrice
														)
													)}
												</td>
												<td>
													{Util.addComma(
														Number(item.realPrice)
													)}
												</td>
												<td>{item.mmIdx}</td>
												<td>{item.nick}</td>
												<td>
													{Util.dateFormat(
														item.regDt
													)}
												</td>
												<td>{item.getChannelName}</td>
												<td>{item.stateName}</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};

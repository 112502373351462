// 보물목록
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	pmIdx: number;
	categoryName: string;
	displayDt: string;
	displayPrice: number;
	notifyYn: number;
	displayYn: number;
	gradeCode: string;
	imageYn: string;
	inventory: string;
	limitCount: string | number;
	name: string;
	regDt: string;
	modDt: string;
	registType: string;
	useYn: number;
	groupYn: number;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [gradeCode, setGradeCode] = useState<string[]>([]);
	const [categoryCode, setCategoryCode] = useState<string[]>([]);
	const [limitCount, setLimitCount] = useState<string[]>([]);
	const [notifyYn, setNotifyYn] = useState<number[]>([]);
	const [displayYn, setDisplayYn] = useState<number[]>([]);
	const [displayDtYn, setDisplayDtYn] = useState<number>(0);
	const [useYn, setUseYn] = useState<number[]>([]);
	const [inventory, setInventory] = useState<number[]>([]);

	const filterList = [
		{
			title: "보물 등급",
			name: "gradeCode",
			items: [
				{ text: "S5등급", value: "S5" },
				{ text: "S4등급", value: "S4" },
				{ text: "S3등급", value: "S3" },
				{ text: "S2등급", value: "S2" },
				{ text: "S1등급", value: "S1" },
				{ text: "S등급", value: "S" },
				{ text: "A등급", value: "A" },
				{ text: "B등급", value: "B" },
				{ text: "C등급", value: "C" },
				{ text: "구매 5만원이상", value: 9 },
				{ text: "D등급", value: "D" },
				{ text: "E등급", value: "E" },
				{ text: "F등급", value: "F" },
			],
			option: gradeCode,
			setOption: setGradeCode,
		},
		{
			title: "보물 분류",
			name: "categoryCode",
			items: [
				{ text: "가구/인테리어", value: 1 },
				{ text: "스포츠/레저", value: 2 },
				{ text: "기프티콘", value: 3 },
				{ text: "식품", value: 4 },
				{ text: "디지털/가전", value: 5 },
				{ text: "장난감/완구", value: 6 },
				{ text: "명품", value: 7 },
				{ text: "패션잡화", value: 8 },
				{ text: "생활/건강", value: 9 },
				{ text: "화장품/미용", value: 10 },
				{ text: "지류상품권", value: 11 },
			],
			option: categoryCode,
			setOption: setCategoryCode,
		},
		{
			title: "수량제한",
			name: "limitCount",
			items: [
				{ text: "있음", value: 1 },
				{ text: "없음", value: "N" },
			],
			option: limitCount,
			setOption: setLimitCount,
		},
		{
			title: "보물 노출여부",
			name: "displayYn",
			items: [
				{ text: "노출", value: 1 },
				{ text: "미노출", value: 0 },
			],
			option: displayYn,
			setOption: setDisplayYn,
		},
		{
			title: "뉴스룸 노출여부",
			name: "notifyYn",
			items: [
				{ text: "노출", value: 1 },
				{ text: "미노출", value: 0 },
			],
			option: notifyYn,
			setOption: setNotifyYn,
		},
		{
			title: "제외 여부",
			name: "useYn",
			items: [
				{ text: "제외", value: 0 },
				{ text: "미제외", value: 1 },
			],
			option: useYn,
			setOption: setUseYn,
		},
		{
			title: "보유 현황",
			name: "inventory",
			items: [
				{ text: "있음", value: 1 },
				{ text: "없음", value: 0 },
			],
			option: inventory,
			setOption: setInventory,
		},
	];
	// 탭
	const [activeTab, setActiveTab] = useState<string | null>("A");
	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList(tab ? tab : activeTab);
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList(activeTab);
	}, [user, page]);
	// 목록 불러오기
	const GetList = async (registType?: string | null) => {
		setLoading(true);
		console.log(filterOptionsRef.current);
		await Api.get("present/list", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
			registType: registType,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.datas);
					setTotalItems(res.data.data.cnt.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 보물 구분
	const registTypeText = (registType: string) => {
		switch (registType) {
			case "B":
				return "일반";
			case "C":
				return "합성";
			case "E":
				return "이벤트";
			default:
				return "-";
		}
	};
	// 노출여부
	const displayYnText = (displayYn: number) => {
		switch (displayYn) {
			case 0:
				return "미노출";
			case 1:
				return "노출";
			default:
				return "-";
		}
	};
	// 제외 여부
	const useYnText = (useYn: number) => {
		switch (useYn) {
			case 0:
				return "제외";
			case 1:
				return "미제외";
			default:
				return "-";
		}
	};
	// 일괄 등록 정보 수정 가능 여부
	const isModifiable = (item: any) => {
		return item.groupYn == 1 && Util.getNowString() < item.displayDt;
	};
	// 보물 노출여부 텍스트
	const isReserv = (displayDt: string, displayYn: number) => {
		if (Util.getNowString() >= displayDt.slice(0, 12)) {
			return displayYn == 1
				? `노출\n${Util.dateFormat(displayDt)}`
				: displayYnText(displayYn);
		} else {
			return `노출 예약\n${Util.dateFormat(displayDt)}`;
		}
	};
	// 보물 정보 수정 팝업 오픈
	const openEditPopup = (pmIdx: number, groupYn: number) => {
		window.open(
			"/PRST001003?pmIdx=" +
				pmIdx +
				"&groupYn=" +
				groupYn +
				"&editAble=" +
				true,
			"_blank",
			"width=600, height=800, left=100, top=100"
		);
	};
	// 보물 일괄 수정 팝업 오픈
	const openEditGroupPopup = (item: any) => {
		viewerCheck(async () => {
			if (isModifiable(item)) {
				localStorage.setItem("sendData", JSON.stringify(item.regDt));
				window.open(
					"/PRST001006",
					"_blank",
					"width=1000, height=650, left=100, top=100"
				);
			}
		});
	};

	// 공통
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setGradeCode([]);
		setCategoryCode([]);
		setLimitCount([]);
		setNotifyYn([]);
		setDisplayYn([]);
		setDisplayDtYn(0);
		setUseYn([]);
		setInventory([]);
	};
	// 필터 적용
	const filter = () => {
		console.log(gradeCode);
		filterOptionsRef.current = {
			gradeCode: gradeCode.join(),
			categoryCode: categoryCode,
			limitCount: limitCount.length >= 2 ? null : limitCount, // 둘 중 하나만 선택되도록
			displayYn: displayYn.length >= 2 ? null : displayYn, // 둘 중 하나만 선택되도록
			displayDtYn:
				displayDtYn == 0
					? displayYn.length >= 2
						? 0
						: null
					: displayYn.length >= 2
					? null
					: displayDtYn,
			notifyYn: notifyYn.length >= 2 ? null : notifyYn, // 둘 중 하나만 선택되도록
			useYn: useYn,
			inventory: inventory.length >= 2 ? null : inventory, // 둘 중 하나만 선택되도록
		};
		pageResset();
		setIsApplied(
			gradeCode.length > 0 ||
				categoryCode.length > 0 ||
				limitCount.length > 0 ||
				displayYn.length > 0 ||
				displayDtYn == 1 ||
				notifyYn.length > 0 ||
				useYn.length > 0 ||
				inventory.length > 0
		);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<>
			<Page
				title="보물 목록"
				contents={
					<div>
						{/* 검색 영역 */}
						<S.searchArea>
							<S.searchRow>
								<select
									name="searchKey"
									id="searchKey"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchKey: e.target.value,
										})
									}
								>
									<option value="">= 선 택 =</option>
									<option value="1">보물번호</option>
									<option value="2">보물명</option>
								</select>
								<input
									type="text"
									id="searchWord"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchWord: e.target.value,
										})
									}
								/>
								<S.searchButton onClick={() => search()}>
									<S.headerText>검색하기</S.headerText>
								</S.searchButton>
								<S.searchButton
									onClick={() => {
										viewerCheck(() => {
											if (
												window.confirm(
													"엑셀을 다운 받으시겠습니까?"
												)
											) {
												alert("엑셀다운로드 개발 전");
											}
										});
									}}
									color={S.color.primaryVariant}
								>
									<S.headerText>엑셀 다운로드</S.headerText>
								</S.searchButton>
								<S.filterButton
									onClick={() => {
										setOpenFilter(!isOpenFilter);
									}}
									className={`${isApplied && "applied"}`}
								>
									{isOpenFilter ? (
										<Minus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									) : (
										<Plus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									)}
								</S.filterButton>
							</S.searchRow>
							{/* 필터영역 */}
							<S.filterArea
								className={`${isOpenFilter && "expend"}`}
							>
								<S.filterWrap>
									{filterList.map(
										(filter: any, fi: number) => {
											return (
												<li key={fi}>
													<h2>{filter.title}</h2>
													<ul>
														<Filter
															fi={fi}
															filter={filter} // 필터 아이템 리스트
															option={
																filter.option
															}
															setOption={
																filter.setOption
															}
														/>

														{filter.name ==
															"displayYn" && (
															<li>
																<input
																	type="checkbox"
																	name="displayDtYn"
																	id="displayDtYn"
																	value={1}
																	onChange={(
																		e
																	) => {
																		e.target
																			.checked
																			? setDisplayDtYn(
																					1
																			  )
																			: setDisplayDtYn(
																					0
																			  );
																	}}
																	checked={
																		displayDtYn ==
																		1
																	}
																/>
																<label htmlFor="displayDtYn">
																	노출 예약
																</label>
															</li>
														)}
													</ul>
												</li>
											);
										}
									)}
								</S.filterWrap>
								<S.flexRowEnd>
									<button onClick={() => filter()}>
										적용
									</button>
									<button onClick={() => filterRefresh()}>
										전체 해제
									</button>
								</S.flexRowEnd>
							</S.filterArea>
						</S.searchArea>
						{/* 버튼 영역 */}
						<S.asideButtons>
							<button
								onClick={() => {
									viewerCheck(() =>
										window.open(
											"/PRST001002",
											"_blank",
											"width=1200, height=900, left=100, top=100"
										)
									);
								}}
							>
								일괄 미노출
							</button>
							<button
								onClick={() => {
									viewerCheck(() =>
										window.open(
											"/PRST001003?editAble=" + true,
											"_blank",
											"width=600, height=800, left=100, top=100"
										)
									);
								}}
							>
								보물 등록
							</button>
							<button
								onClick={() => {
									viewerCheck(() =>
										window.open(
											"/PRST001004",
											"_blank",
											"width=600, height=430, left=100, top=100"
										)
									);
								}}
							>
								보물 일괄 등록
							</button>
							<button
								onClick={() => {
									viewerCheck(() =>
										window.open(
											"/PRST001005",
											"_blank",
											"width=600, height=380, left=100, top=100"
										)
									);
								}}
							>
								유의사항 수정
							</button>
						</S.asideButtons>
						{/* 탭 영역 */}
						<S.tabArea>
							<button
								className="tab active"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("A");
									pageResset("A");
								}}
							>
								전체
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("B");
									pageResset("B");
								}}
							>
								일반
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("C");
									pageResset("C");
								}}
							>
								합성
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab("E");
									pageResset("E");
								}}
							>
								이벤트
							</button>
						</S.tabArea>
						{/* 테이블 영역 */}
						<S.table01Wrap>
							<S.table01>
								<thead>
									<tr>
										<th
											className="sort down"
											onClick={(e) => {
												Util.sorting({
													e, // 이벤트 대상
													searchOptionsRef,
													searchOptions,
													sortType: 1,
												});
												pageResset();
											}}
										>
											보물번호
										</th>
										<th>보물 구분</th>
										<th>보물명</th>
										<th
											className="sort"
											onClick={(e) => {
												Util.sorting({
													e, // 이벤트 대상
													searchOptionsRef,
													searchOptions,
													sortType: 6,
												});
												pageResset();
											}}
										>
											보물 등급
										</th>
										<th>보물 분류</th>
										<th
											className="sort"
											onClick={(e) => {
												Util.sorting({
													e, // 이벤트 대상
													searchOptionsRef,
													searchOptions,
													sortType: 2,
												});
												pageResset();
											}}
										>
											정가(원)
										</th>
										<th
											className="sort"
											onClick={(e) => {
												Util.sorting({
													e, // 이벤트 대상
													searchOptionsRef,
													searchOptions,
													sortType: 3,
												});
												pageResset();
											}}
										>
											수량제한
										</th>
										<th>보물 노출여부</th>
										<th>뉴스룸 노출여부</th>
										<th>제외 여부</th>
										<th
											className="sort"
											onClick={(e) => {
												Util.sorting({
													e, // 이벤트 대상
													searchOptionsRef,
													searchOptions,
													sortType: 5,
												});
												pageResset();
											}}
										>
											등록 일시
										</th>
										<th
											className="sort"
											onClick={(e) => {
												Util.sorting({
													e, // 이벤트 대상
													searchOptionsRef,
													searchOptions,
													sortType: 4,
												});
												pageResset();
											}}
										>
											보유 현황(개)
										</th>
										<th>상세정보</th>
									</tr>
								</thead>
								<tbody>
									{list && list.length > 0 ? (
										<>
											{list.map((item, i) => (
												<tr key={i}>
													<td>{item.pmIdx}</td>
													<td>
														{registTypeText(
															item.registType
														)}
													</td>
													<td>{item.name}</td>
													<td>{item.gradeCode}</td>
													<td>{item.categoryName}</td>
													<td>
														{Util.addComma(
															item.displayPrice
														)}
													</td>
													<td>
														{item.limitCount ==
															"" ||
														item.limitCount == "N"
															? "없음"
															: item.limitCount}
													</td>
													<td
														className={`wrap ${
															isModifiable(
																item
															) && "Clickable"
														}`}
														onClick={() =>
															isModifiable(
																item
															) &&
															openEditGroupPopup(
																item
															)
														}
													>
														{isReserv(
															item.displayDt,
															item.displayYn
														)}
													</td>
													<td>
														{displayYnText(
															item.notifyYn
														)}
													</td>
													<td>
														{useYnText(item.useYn)}
													</td>
													<td className="wrap">
														{Util.dateFormat(
															item.regDt
														)}
														{item.regDt !==
															item.modDt &&
															`\n(${Util.dateFormat(
																item.modDt
															)})`}
													</td>
													<td>{item.inventory}</td>
													<td>
														<S.tableButton
															onClick={() => {
																openEditPopup(
																	item.pmIdx,
																	item.groupYn
																);
															}}
														>
															<S.headerText>
																자세히 보기
															</S.headerText>
														</S.tableButton>
													</td>
												</tr>
											))}
										</>
									) : (
										<tr>
											<td
												colSpan={999}
												style={{ textAlign: "center" }}
											>
												데이터가 없습니다.
											</td>
										</tr>
									)}
								</tbody>
							</S.table01>
						</S.table01Wrap>
						{/* 페이지네이션 영역 */}
						<Pagination
							totalItems={totalItems} // 데이터 총 개수
							currentPage={
								page && parseInt(page) > 0 ? parseInt(page) : 1
							} // 현재 페이지
							itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
						/>
					</div>
				}
			/>
		</>
	);
};

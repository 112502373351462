// 관리자 지급/회수 목록
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";

interface list {
	aahIdx: string;
	aah_action: string;
	changeType: string;
	mmIdx: string;
	nick: string;
	reason: string;
	regDt: string;
	type: number;
	used: string;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	const [stateCnt, setStateCnt] = useState<any>();
	const now = new Date(Util.getNow());
	// 검색 옵션(1개월 기본)
	const searchOptionsRef = useRef<{}>({
		searchStrDt: Util.daysAgo(now, 30),
		searchEndDt: Util.getNowString().slice(0, 8),
	});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const [action, setAction] = useState<string[]>([]);
	const [changeType, setChangeType] = useState<string[]>([]);

	const filterList = [
		{
			title: "구분",
			name: "action",
			items: [
				{ text: "지급", value: 1 },
				{ text: "예약 지급", value: 2 },
				{ text: "회수", value: 0 },
			],
			option: action,
			setOption: setAction,
		},
		{
			title: "타입",
			name: "changeType",
			items: [
				{ text: "이벤트", value: 23 },
				{ text: "배송 환급", value: 26 },
				{ text: "테스트", value: 21 },
				{ text: "기타", value: 24 },
			],
			option: changeType,
			setOption: setChangeType,
		},
	];
	// 탭
	const [activeTab, setActiveTab] = useState<number | null>(0);
	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: number) => {
		if (page == "1") {
			user.isLogin && GetList(tab == undefined ? 0 : tab);
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		setDate(30);
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList(activeTab);
	}, [user, page]);
	// 목록 불러오기
	const GetList = async (type?: number | null) => {
		setLoading(true);
		console.log(searchOptionsRef.current);
		await Api.get("payment/actionList", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
			type: type == 1 ? [1, 2] : type,
		})
			.then((res: any) => {
				console.log(res);
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					setList(res.data.data.data);
					setStateCnt(res.data.data.groupCnt);
					setTotalItems(res.data.data.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response, "wwwwww");
			});
		setLoading(false);
	};
	// 구분
	const actionText = (action: string) => {
		switch (action) {
			case "1":
				return "지급";
			case "0":
				return "회수";
			case "2":
				return "예약 지급";
			default:
				return "-";
		}
	};
	// 타입 구분
	const typeText = (type: number) => {
		switch (type) {
			case 0:
				return "포인트";
			case 1:
				return "이벤트상자";
			case 2:
				return "골드상자";
			default:
				return "-";
		}
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setAction([]);
		setChangeType([]);
	};
	// 필터 적용
	const filter = () => {
		console.log(action);
		filterOptionsRef.current = {
			action: action.join(),
			changeType: changeType.join(),
		};
		pageResset();
		setIsApplied(action.length > 0 || changeType.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};
	return (
		<>
			<Page
				title="관리자 지급/회수 목록"
				contents={
					<div>
						{/* 검색 영역 */}
						<S.searchArea>
							<S.searchRow>
								<input
									type="date"
									name="searchStrDt"
									id="searchStrDt"
									value={searchStrDt}
									onChange={(e) => {
										setSearchStrDt(e.target.value);
									}}
								/>
								<p> - </p>
								<input
									type="date"
									name="searchEndDt"
									id="searchEndDt"
									value={searchEndDt}
									onChange={(e) => {
										setSearchEndDt(e.target.value);
									}}
								/>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio1"
									value={""}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio1">전체</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio2"
									value={7}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio2">1주일</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio3"
									value={30}
									defaultChecked
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio3">1개월</label>
								<input
									name="searchDtRadio"
									type="radio"
									id="searchDtRadio4"
									value={90}
									onClick={(event) =>
										setDate(
											(event.target as HTMLInputElement)
												.value
										)
									}
								/>
								<label htmlFor="searchDtRadio4">3개월</label>
							</S.searchRow>
							<S.searchRow>
								<select
									name="searchKey"
									id="searchKey"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchKey: e.target.value,
										})
									}
								>
									<option value="">= 선 택 =</option>
									<option value="1">회원번호</option>
									<option value="2">닉네임</option>
								</select>
								<input
									type="text"
									id="searchWord"
									onChange={(e) =>
										setSearchOptions({
											...searchOptions,
											searchWord: e.target.value,
										})
									}
								/>
								<S.searchButton onClick={() => search()}>
									<S.headerText>검색하기</S.headerText>
								</S.searchButton>
								<S.searchButton
									onClick={() => {
										viewerCheck(() => {
											if (
												window.confirm(
													"엑셀을 다운 받으시겠습니까?"
												)
											) {
												alert("엑셀다운로드 개발 전");
											}
										});
									}}
									color={S.color.primaryVariant}
								>
									<S.headerText>엑셀 다운로드</S.headerText>
								</S.searchButton>
								<S.filterButton
									onClick={() => {
										setOpenFilter(!isOpenFilter);
									}}
									className={`${isApplied && "applied"}`}
								>
									{isOpenFilter ? (
										<Minus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									) : (
										<Plus
											width="2rem"
											height="2rem"
											fill="white"
										/>
									)}
								</S.filterButton>
							</S.searchRow>
							{/* 필터영역 */}
							<S.filterArea
								className={`${isOpenFilter && "expend"}`}
							>
								<S.filterWrap>
									{filterList.map(
										(filter: any, fi: number) => {
											return (
												<li key={fi}>
													<h2>{filter.title}</h2>
													<ul>
														<Filter
															fi={fi}
															filter={filter} // 필터 아이템 리스트
															option={
																filter.option
															}
															setOption={
																filter.setOption
															}
														/>
													</ul>
												</li>
											);
										}
									)}
								</S.filterWrap>
								<S.flexRowEnd>
									<button onClick={() => filter()}>
										적용
									</button>
									<button onClick={() => filterRefresh()}>
										전체 해제
									</button>
								</S.flexRowEnd>
							</S.filterArea>
						</S.searchArea>
						{/* 버튼 영역 */}
						<S.asideButtons>
							<button
								onClick={() => {
									viewerCheck(() =>
										window.open(
											"/CHPO001002",
											"_blank",
											"width=900, height=800, left=100, top=100"
										)
									);
								}}
							>
								지급/회수
							</button>
						</S.asideButtons>
						{/* 통계 영역 */}
						<S.statsArea>
							{stateCnt && stateCnt.length > 0 ? (
								stateCnt.map((item: any, index: number) => {
									return (
										<p key={index}>
											{item.actionType}
											<S.headerText>
												{Util.addComma(item.cnt)}
											</S.headerText>
											건({Util.addComma(item.used)}
											{activeTab == 0 ? " P" : " 개"})
										</p>
									);
								})
							) : (
								<></>
							)}
						</S.statsArea>
						{/* 탭 영역 */}
						<S.tabArea>
							<button
								className="tab active"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab(0);
									pageResset(0);
								}}
							>
								포인트
							</button>
							<button
								className="tab"
								onClick={(e) => {
									Util.tab({ e });
									setActiveTab(1);
									pageResset(1);
								}}
							>
								상자
							</button>
						</S.tabArea>
						{/* 테이블 영역 */}
						<S.table01Wrap>
							<S.table01>
								<thead>
									<tr>
										<th>번호</th>
										<th>구분</th>
										<th>회원 번호</th>
										<th>회원 닉네임</th>
										<th>타입</th>
										{activeTab !== 0 && <th>상자 종류</th>}
										<th>
											{activeTab !== 0
												? "수량(개)"
												: "포인트(P)"}
										</th>
										<th>일시</th>
										<th>사유</th>
									</tr>
								</thead>
								<tbody>
									{list && list.length > 0 ? (
										<>
											{list.map((item, i) => (
												<tr key={i}>
													<td>
														{totalItems -
															i -
															(page
																? Number(page) -
																  1
																: 0) *
																listSize}
													</td>
													<td>
														{actionText(
															item.aah_action
														)}
													</td>
													<td>{item.mmIdx}</td>
													<td>{item.nick}</td>
													<td>{item.changeType}</td>
													{activeTab !== 0 && (
														<td>
															{typeText(
																item.type
															)}
														</td>
													)}
													<td>
														{Util.addComma(
															Number(item.used)
														)}
													</td>
													<td>
														{Util.dateFormat(
															item.regDt
														)}
													</td>
													<td>{item.reason}</td>
												</tr>
											))}
										</>
									) : (
										<tr>
											<td
												colSpan={999}
												style={{ textAlign: "center" }}
											>
												데이터가 없습니다.
											</td>
										</tr>
									)}
								</tbody>
							</S.table01>
						</S.table01Wrap>
						{/* 페이지네이션 영역 */}
						<Pagination
							totalItems={totalItems} // 데이터 총 개수
							currentPage={
								page && parseInt(page) > 0 ? parseInt(page) : 1
							} // 현재 페이지
							itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
						/>
					</div>
				}
			/>
		</>
	);
};

// 이미지 미리보기

import * as S from "../Styles/Style";

export default () => {
	const sendData = localStorage.getItem("sendData");
	const image = JSON.parse(sendData ? sendData : "");

	return (
		<>
			<S.popupPage>
				<img src={image} />
			</S.popupPage>
		</>
	);
};

export default ({ fi, filter, option, setOption }: any) => {
	return (
		<>
			{filter.items.map((item: any, ii: number) => {
				return (
					<li key={ii}>
						<input
							type="checkbox"
							name={filter.name}
							id={filter.name + fi + "_" + ii}
							value={item.value}
							onChange={(e) => {
								e.target.checked
									? setOption((prev: any) => [
											...prev,
											item.value,
									  ])
									: setOption(
											option.filter(
												(i: any) => i !== item.value
											)
									  );
							}}
							checked={option && option.includes(item.value)}
						/>
						<label htmlFor={filter.name + fi + "_" + ii}>
							{item.text}
						</label>
					</li>
				);
			})}
		</>
	);
};

// 공통 스타일
import styled from "styled-components";

// 이미지
import Chevrons from "../Images/chevrons.svg";
import Sort from "../Images/sort.svg";
import SortUp from "../Images/sortUp.svg";
import SortDown from "../Images/sortDown.svg";
import Close from "../Images/close.svg";

// 색상
const color = {
	primary: "#FF9800",
	primaryVariant: "#e8580a",
	primaryLight: "#fdf7f0",
	// secondary: "#4A5783",
	secondary: "#3D5381",
	point01: "#944697",
	point02: "#DC5687",
	point03: "#FFBB5C",
	point04: "#F9F871",
	red: "#de1a00",
	gray01: "#F5F5F5",
	gray02: "#E0E0E0",
	gray03: "#9E9E9E",
	gray04: "#616161",
	gray05: "#212121",
	dimmedPrimary: "#22418fcb",
	dimmed40: "rgba(0, 0, 0, 0.4)",
	dimmed70: "rgba(0, 0, 0, 0.7)",
};
// 텍스트 - 타이틀
const mainTitle = styled.span<{ color?: string; fontSize?: string }>`
	color: ${(props) => props.color && props.color};
	font-size: ${(props) => props.fontSize && props.fontSize};
	font-family: "Jalnan2";
	text-align: center;
`;
const headerText = styled.span<{ color?: string; fontSize?: string }>`
	color: ${(props) => props.color && props.color};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "1.6rem")};
	font-family: "IBMPlexSansKRSemiBold";
`;
const bodyText = styled.span<{ color?: string; fontSize?: string }>`
	color: ${(props) => props.color && props.color};
	font-size: ${(props) => (props.fontSize ? props.fontSize : "1.2rem")};
	font-family: "IBMPlexSansKR";
`;

// blank
const blank1 = styled.div`
	margin-top: 1rem;
`;
const blank2 = styled.div`
	margin-top: 2rem;
`;
const blank3 = styled.div`
	margin-top: 3rem;
`;
const blank4 = styled.div`
	margin-top: 4rem;
`;
const blank5 = styled.div`
	margin-top: 5rem;
`;
// flex
const flexRowStart = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
const flexRowCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const flexRowBetween = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const flexRowEnd = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

// 인풋
const normalRadioArea = styled.div`
	display: flex;
	align-items: center;
	& > label {
		margin-right: 1rem;
	}
`;
const normalRadio = styled(flexRowCenter)`
	input[type="radio"] {
		display: none;
	}
	label {
		width: 2rem;
		height: 2rem;
		display: inline-block;
		border: 2px solid ${color.gray02};
		background-color: white;
		border-radius: 50%;
	}
	input[type="radio"]:checked + label {
		border: 5px solid ${color.primary};
	}
	input[type="radio"]:disabled + label {
		border: 2px solid ${color.gray02};
		background-color: ${color.gray01};
	}
	input[type="radio"]:checked:disabled + label {
		border: 5px solid ${color.gray02};
	}
`;
const normalLabel = styled.label`
	font-size: 1.2rem;
	margin-left: 0.5rem;
`;
const switchRadioArea = styled.div`
	width: fit-content;
	display: flex;
	align-items: center;
	border-radius: 2rem;
	background-color: ${color.primaryLight};
	overflow: hidden;
	flex-shrink: 0;
`;
const switchRadio = styled(flexRowCenter)`
	input[type="radio"] {
		display: none;
	}
	label {
		min-width: 10rem;
		text-align: center;
		display: inline-block;
		padding: 1rem 2rem;
		border-radius: 0rem;
		font-family: "IBMPlexSansKRSemiBold";
		font-size: 1.2rem;
		color: ${color.primary};
	}
	input[type="radio"]:checked + label {
		background-color: ${color.primary};
		color: white;
	}
`;
const normalCheckbox = styled(flexRowCenter)`
	input[type="checkbox"] {
		display: none;
	}
	label {
		width: 1.5rem;
		height: 1.5rem;
		display: inline-block;
		border: 2px solid ${color.gray02};
		background-color: white;
		border-radius: 0.5rem;
	}
	input[type="checkbox"]:checked + label {
		border-width: 3px;
		background-color: ${color.primary};
	}
`;
// 인풋 - 파일
const normalFile = styled(flexRowCenter)`
	input[type="file"] {
		display: none;
	}
	label {
		height: 100%;
		border: 1px solid ${color.primary};
		padding: 0 1rem;
		display: flex;
		align-items: center;
		flex-shrink: 0;
		span {
			color: ${color.primary};
			font-size: 1.2rem;
		}
	}
	p {
		height: 100%;
		flex-grow: 1;
		font-size: 1.2rem;
		border: 1px solid ${color.gray02};
		display: flex;
		align-items: center;
		padding: 0 1rem;
	}
	button {
		height: 100%;
		background-color: ${color.primary};
		padding: 0 2rem;
		color: white;
		font-family: "IBMPlexSansKRSemiBold";
		font-size: 1.2rem;
		margin-left: 0.5rem;
		flex-shrink: 0;
	}
`;
// 박스안의 인풋
const inputInBox = styled(flexRowCenter)`
	height: 3.5rem;
	flex-grow: 1;
	border-bottom: 1px solid ${color.gray02};
	border: 1px solid ${color.gray02};
	padding: 0 1rem;
	margin-right: 0.5rem;
	&:last-child {
		margin-right: 0;
	}
	span {
		font-size: 1.2rem;
		flex-shrink: 0;
	}
	input {
		width: 1rem;
		flex-grow: 1;
		flex-shrink: 1;
		margin: 0 1rem;
		font-size: 1.2rem;
		background-color: transparent;
	}
	svg {
		flex-shrink: 0;
		width: 1.8rem;
		height: 1.8rem;
	}
`;
// 툴팁
const tooltipButton = styled.button`
	position: relative;
	.tooltip {
		width: max-content;
		/* display: none; */
		position: absolute;
		top: calc(100% + 0.5rem);
		left: 50%;
		background-color: ${color.gray01};
		border: 1px solid ${color.gray03};
		border-radius: 5px;
		z-index: 100;
		transform: translate(-50%);
		padding: 0.5rem;
		span {
			color: ${color.gray05};
			display: block;
			margin-bottom: 0.3rem;
		}
		span:last-child {
			margin-bottom: 0;
		}
	}
	&:hover {
		opacity: 1;
	}
`;

// 버튼
const filledButton = styled.button`
	padding: 1.2rem 5rem;
	background-color: ${color.primaryVariant};
	color: white;
	font-size: 1.2rem;
	font-family: "IBMPlexSansKRSemiBold";
	border-radius: 0.5rem;
	margin-left: 1rem;
	&.disabled {
		background-color: ${color.gray03};
	}
	&:first-child {
		background-color: ${color.primary};
		margin-left: 0;
	}
`;
const plusMinusButton = styled(flexRowCenter)`
	button {
		background-color: ${color.secondary};
		border-radius: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.8rem 4rem;
		margin: 0 0.5rem;
		span {
			margin-left: 1rem;
			font-family: "IBMPlexSansKRSemiBold";
			font-size: 1.2rem;
			color: white;
		}
		svg {
			width: 1.8rem;
			height: 1.8rem;
			fill: white;
		}
	}
	button:last-child {
		background-color: white;
		border: 1px solid ${color.secondary};
		span {
			color: ${color.secondary};
		}
		svg {
			fill: ${color.secondary};
		}
	}
`;
const tableButtonArea = styled(flexRowCenter)`
	button {
		margin-right: 0.2rem;
	}
	button:last-child {
		margin-right: 0;
	}
`;
const tableButton = styled.button`
	width: 100%;
	border: 1px solid ${color.primary};
	padding: 0.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 1rem;
		color: ${color.primary};
	}
`;
const textButton = styled.button`
	border-bottom: 1px solid ${color.gray01};
`;
const asideButtons = styled(flexRowEnd)`
	margin-bottom: 2rem;
	button {
		font-size: 1.2rem;
		padding: 0 2rem;
		border-right: 1px solid ${color.gray02};
		display: flex;
		align-items: center;
	}
	button:last-child {
		border-right: 0;
		padding-right: 0;
	}
	button::after {
		content: "";
		width: 1.8rem;
		height: 1.8rem;
		display: block;
		margin-left: 1rem;
		background-image: url(${Chevrons});
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
`;
const asideFilledButtons = styled(flexRowEnd)`
	margin-bottom: 1rem;
	button {
		font-size: 1.2rem;
		padding: 0.4rem 2rem;
		display: flex;
		align-items: center;
		/* background-color: ${color.primaryLight}; */
		/* border: 1px solid ${color.primary}; */
		border: 1px solid ${color.gray05};
		/* border-top: 1px solid ${color.primary}; */
		/* border-bottom: 1px solid ${color.primary}; */
		color: ${color.gray05};
		/* color: ${color.primary}; */
		margin-right: 0.5rem;
	}
	button:last-child {
		margin-right: 0;
	}
	button:disabled {
		background-color: white;
		/* background-color: ${color.gray01}; */
		border: 1px solid ${color.gray02};
		color: ${color.gray03};
	}
`;
// 통계 영역
const statsArea = styled(flexRowEnd)`
	margin-bottom: 0.5rem;
	p {
		font-size: 1.4rem;
		margin-left: 2rem;
		display: flex;
		align-items: center;
	}
	span {
		/* color: ${color.primary}; */
		/* font-size: 1.6rem; */
		margin-left: 0.5rem;
		margin-right: 0.2rem;
	}
`;
// 탭
const tabArea = styled.div`
	border-bottom: 1px solid ${color.gray03};
	button {
		height: 100%;
		position: relative;
		padding: 0.5rem 2rem;
		font-size: 1.2rem;
		color: ${color.gray04};
		font-family: "IBMPlexSansKRSemiBold";
	}
	button::after {
		content: "";
		display: none;
		width: 100%;
		height: 2px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		background-color: ${color.primaryVariant};
	}
	button.active {
		color: ${color.primaryVariant};
	}
	button.active::after {
		display: block;
	}
`;
// 테이블
// 메인 테이블
const table01Wrap = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	overflow-x: scroll;
`;
const table01 = styled.table`
	min-width: 100%;
	border-top: 1px solid ${color.gray03};
	th,
	td {
		max-width: 80rem;
		padding: 0.5rem;
		border-right: 1px solid ${color.gray02};
		font-size: 1.2rem;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	th.wrap,
	td.wrap {
		white-space: pre;
	}
	th.Clickable,
	td.Clickable {
		text-decoration: underline;
		cursor: pointer;
	}
	th:last-child,
	td:last-child {
		border-right: 0;
	}
	tr {
		border-bottom: 1px solid ${color.gray02};
	}
	tr.checked {
		background-color: ${color.gray01};
	}
	/* 필터, 정렬 */
	th {
		position: relative;
		background-color: ${color.primaryLight};
		padding: 0.5rem 1.2rem;
	}
	th.sort::after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 1.2rem;
		height: 1.2rem;
		background-size: contain;
		background-position: center;
		margin-left: 0.5rem;
		background-image: url(${Sort});
		background-repeat: no-repeat;
	}
	th.up::after {
		background-image: url(${SortUp});
	}
	th.down::after {
		background-image: url(${SortDown});
	}
	.iconArea {
		svg {
			width: 1.5rem;
			height: 1.5rem;
			margin: 0 0.2rem;
		}
	}
`;
// 팝업 테이블
const table02 = styled.table`
	width: 100%;
	border-collapse: separate;
	table-layout: fixed;
	& > tbody > tr > th {
		padding-top: 1rem;
		vertical-align: top;
		text-align: left;
		font-size: 1.2rem;
		color: ${color.gray04};
	}
	& > tbody > tr > td {
		height: 4.5rem;
		font-size: 1.2rem;
		color: ${color.gray04};
	}
	td > div {
		height: 100%;
		display: flex;
		align-items: center;
	}
	td span {
		margin: 0 0.5rem;
	}
	input[type="text"],
	input[type="number"],
	input[type="datetime-local"],
	select {
		width: 100%;
		height: 100%;
		flex-grow: 1;
		flex-shrink: 1;
		font-size: 1.2rem;
		padding: 0 1rem;
		border: 1px solid ${color.gray02};
		display: inline-block;
	}
	input.radioInput {
		width: 1rem;
	}
	label {
		font-size: 1.2rem;
	}
	input:read-only {
		background-color: ${color.gray01};
	}
	.imageArea {
		height: unset;
		& > div > div {
			width: 1%;
			height: 10rem;
			flex-grow: 1;
			flex-shrink: 1;
			margin-right: 0.5rem;
			display: flex;
			justify-content: center;
			&:last-child {
				margin-right: 0;
			}
		}
	}
`;
// 작은 테이블
const table03 = styled.table`
	width: 100%;
	border-top: 1px solid ${color.gray02};
	border-bottom: 1px solid ${color.gray02};
	margin-top: 2rem;
	th {
		width: 1%;
		white-space: nowrap;
		padding: 0.5rem;
		background-color: ${color.gray01};
		font-size: 1.2rem;
	}
	th.numbering {
		background-color: ${color.gray04};
		color: white;
	}
	th.delete {
		background-color: ${color.secondary};
		color: white;
		cursor: pointer;
		svg {
			width: 1.5rem;
		}
	}
	tr {
		border-bottom: 1px dashed ${color.gray02};
	}
	td {
		padding: 0 1rem;
		div {
			display: flex;
		}
		svg {
			width: 1.8rem;
			height: 1.8rem;
		}
	}
	input,
	select,
	textarea {
		flex-grow: 1;
		flex-shrink: 1;
		font-size: 1.2rem;
	}
`;
// 보물 이미지 선택
const blankImageArea = styled.div`
	border: 1px dashed ${color.gray02};
	flex-direction: column;
	background-color: ${color.gray01};
	align-items: center;
	&.disabled {
		background-color: ${color.gray02};
	}
	h3 {
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
	input[type="file"] {
		display: none;
	}
	input[type="file"]:disabled + label {
		background-color: ${color.gray01};
	}
	label {
		background-color: ${color.gray02};
		padding: 0.3rem 2rem;
		font-size: 1.2rem;
	}
`;
const filledImagesArea = styled.div`
	border: 1px solid ${color.gray02};
	position: relative;
	overflow: hidden;
	align-items: flex-start;
	justify-content: flex-end !important;
	img {
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 0;
	}
	button {
		background-color: ${color.dimmed70};
		color: white;
		font-size: 1.2rem;
		background-image: url(${Close});
		width: 2.5rem;
		height: 2.5rem;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
`;
// 보물 유의사항
const textAreaWrap = styled.div`
	textarea {
		width: 100%;
		font-size: 1.2rem;
		border: 1px solid ${color.gray02};
		padding: 1rem;
	}
	textarea:read-only {
		background-color: ${color.gray01};
	}
`;

// 페이지
const page = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	flex-direction: column;
	&::-webkit-scrollbar {
		display: none;
	}
`;
const pageTitle = styled.div`
	width: 100%;
	height: 4rem;
	margin: 2rem 0;
	span {
		font-size: 2.5rem;
	}
`;
const popupPage = styled.div`
	padding: 2rem;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	&::-webkit-scrollbar {
		display: none;
	}
	h1 {
		padding-bottom: 1rem;
		border-bottom: 1px solid ${color.gray02};
		text-align: center;
		span {
			font-size: 2rem;
		}
	}
	h2 {
		font-size: 1.6rem;
		display: flex;
		align-items: center;
		button {
			background-color: ${color.gray04};
			padding: 0.2rem 1rem;
			margin-left: 1rem;
			border-radius: 3rem;
			span {
				font-size: 1rem;
				color: white;
			}
		}
	}
	form h2 {
		margin-top: 3rem;
		margin-bottom: 1rem;
		&:first-child {
			margin-top: 1rem;
		}
	}
`;
// 검색 영역
const searchArea = styled.div`
	width: 100%;
	background-color: ${color.gray01};
	margin-bottom: 1rem;
	position: relative;
`;
const searchRow = styled(flexRowCenter)`
	height: 3rem;
	min-height: 3rem;
	margin: 0.5rem;
	select,
	input,
	label {
		background-color: white;
		height: 100%;
		border: 1px solid ${color.gray02};
		padding: 0.2rem 1rem;
		font-size: 1.2rem;
	}
	input {
		flex-grow: 1;
	}
	input[type="radio"] {
		display: none;
	}
	select {
		border-right: 0;
	}
	label {
		line-height: 200%;
		margin-left: 0.2rem;
	}
	input[type="radio"]:checked + label {
		border-color: ${color.primary};
		color: ${color.primary};
	}
	p {
		margin: 0 1rem;
		font-size: 2rem;
	}
`;
const searchButton = styled.button<{ color?: string }>`
	height: 100%;
	background-color: ${(props) => (props.color ? props.color : color.primary)};
	margin-left: 0.2rem;
	padding: 0 2rem;
	span {
		color: white;
		font-size: 1rem;
	}
`;
const filterButton = styled(searchButton)`
	width: 3rem;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${color.gray03};
	&.applied {
		background-color: ${color.secondary};
	}
`;
const filterArea = styled.div`
	height: 0;
	overflow: hidden;
	&.expend {
		height: unset;
		margin: 0.5rem;
	}
	button {
		padding: 0.5rem 2rem;
		background-color: white;
		border: 1px solid ${color.secondary};
		color: ${color.secondary};
		margin-top: 0.5rem;
		margin-left: 0.5rem;
		font-family: "IBMPlexSansKRSemiBold";
	}
`;
const filterWrap = styled.ul`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	display: flex;
	flex-wrap: wrap;
	background-color: white;
	border: 1px solid ${color.gray02};
	border-bottom: 0;
	& > li {
		flex-grow: 1;
		display: flex;
		align-items: center;
		padding: 1rem 1rem 0.5rem;
		border-bottom: 1px solid ${color.gray02};
		border-top: 0;
	}
	& > li:first-child {
		/* border-top: 1px solid ${color.gray02}; */
	}
	h2 {
		min-width: 8rem;
		font-size: 1.2rem;
		padding: 0 1rem;
	}
	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	li {
		display: flex;
		align-items: center;
	}
	input {
		display: none;
	}
	input:checked + label {
		background-color: ${color.gray01};
		border-color: ${color.secondary};
		color: ${color.secondary};
	}
	label {
		min-width: 3rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
		border: 1px solid ${color.gray03};
		padding: 0.5rem 2rem;
		border-radius: 2rem;
		font-family: "IBMPlexSansKRSemiBold";
		color: ${color.gray04};
	}
	& > div {
		width: 100%;
	}
`;

export {
	color,
	mainTitle,
	headerText,
	bodyText,
	normalRadioArea,
	normalRadio,
	normalLabel,
	switchRadioArea,
	switchRadio,
	normalCheckbox,
	normalFile,
	inputInBox,
	tooltipButton,
	filledButton,
	plusMinusButton,
	tableButtonArea,
	tableButton,
	textButton,
	asideButtons,
	asideFilledButtons,
	tabArea,
	statsArea,
	filterButton,
	filterArea,
	filterWrap,
	table01Wrap,
	table01,
	table02,
	table03,
	blankImageArea,
	filledImagesArea,
	textAreaWrap,
	blank1,
	blank2,
	blank3,
	blank4,
	blank5,
	flexRowStart,
	flexRowCenter,
	flexRowBetween,
	flexRowEnd,
	page,
	pageTitle,
	popupPage,
	searchArea,
	searchRow,
	searchButton,
};

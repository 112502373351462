// 회원 목록
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { LoginAtom } from "../Recoil/Atoms/PopupState";
import { UserAtom, LoadingAtom, MenuAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Api from "../libs/api";
import Util from "../Store/Util";
import Page from "../Components/Page";
import Pagination from "../Components/Pagination";
import Filter from "../Components/Filter";

// 이미지
import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as Minus } from "../Images/minus.svg";
import { ReactComponent as Mail } from "../Images/mail.svg";
import { ReactComponent as Sms } from "../Images/sms.svg";
import { ReactComponent as Push } from "../Images/push.svg";

interface list {
	boxCnt: string;
	email: string;
	freePoint: number;
	inventory: string;
	joinDt: string;
	lastLogin: string;
	mmIdx: string;
	nick: string;
	stateCode: string;
	stateName: string;
	mailSendYn: number;
	smsSendYn: number;
}

export default () => {
	const location = useLocation();
	const menu = useRecoilValue(MenuAtom);
	let agmIdx: number;
	const user = useRecoilValue(UserAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);
	const setLoading = useSetRecoilState<boolean>(LoadingAtom);
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get("page");
	const listSize = 20;
	const [totalItems, setTotalItems] = useState<number>(0);
	const [list, setList] = useState<list[] | null>([]);
	const [stateCnt, setStateCnt] = useState<any>();
	// 검색 옵션
	const searchOptionsRef = useRef({});
	const [searchOptions, setSearchOptions] = useState({});
	// 검색 일자
	const now = new Date(Util.getNow());
	const [searchStrDt, setSearchStrDt] = useState<string>("");
	const [searchEndDt, setSearchEndDt] = useState<string>(
		Util.inputDateFormat(now, "date")
	);
	// 필터 옵션
	const filterOptionsRef = useRef({});
	const [stateCode, setStateCode] = useState<string[]>([]);
	const [isApplied, setIsApplied] = useState<boolean>(false);
	const filterList = [
		{
			title: "회원 상태",
			name: "stateCode",
			items: [
				{ text: "가입 전", value: "01,02" },
				{ text: "가입", value: "11" },
				{ text: "탈퇴", value: "-900,-999" },
				{ text: "제한", value: "-10,-20,-30" },
				{ text: "테스터", value: "12" },
			],
			option: stateCode,
			setOption: setStateCode,
		},
	];

	// 1페이지로 이동되면 리셋
	const pageResset = (tab?: string) => {
		if (page == "1") {
			user.isLogin && GetList();
		} else {
			setSearchParams({ page: "1" });
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치 맨 위로 초기화
		user.isLogin && GetList();
	}, [page, user]);

	// 목록 불러오기
	const GetList = async () => {
		setLoading(true);
		await Api.get("member/list", {
			...searchOptionsRef.current,
			...filterOptionsRef.current,
			page: page,
			listSize: listSize,
		})
			.then((res: any) => {
				if (res.data == null) {
					const code = res.response.status;
					if (code == 401) {
						setOpenLogin(true);
					}
				} else {
					console.log(res.data.data);
					setList(res.data.data.datas);
					setStateCnt(res.data.data.stateCnt);
					setTotalItems(res.data.data.cnt);
				}
			})
			.catch((error) => {
				console.log(error.response.data, "wwwwww");
			});
		setLoading(false);
	};
	// 상태
	const stateText = (state: string | null) => {
		switch (state) {
			case "00":
				return "가입 전";
			case "01":
				return "가입 전";
			case "02":
				return "가입 전";
			case "11":
				return "가입";
			case "12":
				return "테스터";
			case "-10":
				return "임시 제한";
			case "-30":
				return "영구 제한";
			case "-999":
				return "탈퇴";
			default:
				return "-";
		}
	};

	// 공통
	// 일자
	const setDate = (value: any) => {
		const year = now.getFullYear(); // 년
		const month = now.getMonth(); // 월
		const day = now.getDate(); // 일

		if (value == "") {
			setSearchStrDt("");
		} else {
			const start = new Date(
				year,
				month,
				day - value
			).toLocaleDateString();
			const startDt = new Date(start);

			setSearchStrDt(Util.inputDateFormat(startDt, "date"));
		}
		setSearchEndDt(Util.inputDateFormat(now, "date"));
	};
	useEffect(() => {
		setSearchOptions({
			...searchOptions,
			searchStrDt: Util.onlyNum(searchStrDt),
			searchEndDt: Util.onlyNum(searchEndDt),
		});
	}, [searchStrDt, searchEndDt]);
	// 검색
	const search = () => {
		searchOptionsRef.current = {
			...searchOptionsRef.current,
			...searchOptions,
		};
		pageResset();
	};
	// 필터 선택 취소
	const filterRefresh = () => {
		setStateCode([]);
	};
	// 필터 적용
	const filter = () => {
		filterOptionsRef.current = { stateCode: stateCode.join() };
		pageResset();
		setIsApplied(stateCode.length > 0);
	};
	// 권한 확인(뷰어 제한) 후 액션
	const viewerCheck = (action: () => void) => {
		menu.map((m) => {
			m.sub.map((s) => {
				if (s.link == location.pathname) {
					agmIdx = s.agmIdx;
				}
			});
		});
		Util.viewerCheck(user.grade, agmIdx, () => action());
	};

	return (
		<Page
			title="회원 목록"
			contents={
				<div>
					{/* 검색 영역 */}
					<S.searchArea>
						<S.searchRow>
							<input
								type="date"
								name="searchStrDt"
								id="searchStrDt"
								value={searchStrDt}
								onChange={(e) => {
									setSearchStrDt(e.target.value);
								}}
							/>
							<p> - </p>
							<input
								type="date"
								name="searchEndDt"
								id="searchEndDt"
								value={searchEndDt}
								onChange={(e) => {
									setSearchEndDt(e.target.value);
								}}
							/>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio1"
								value={""}
								defaultChecked
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio1">전체</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio2"
								value={7}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio2">1주일</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio3"
								value={30}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio3">1개월</label>
							<input
								name="searchDtRadio"
								type="radio"
								id="searchDtRadio4"
								value={90}
								onClick={(event) =>
									setDate(
										(event.target as HTMLInputElement).value
									)
								}
							/>
							<label htmlFor="searchDtRadio4">3개월</label>
						</S.searchRow>
						<S.searchRow>
							<select
								name="searchKey"
								id="searchKey"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchKey: e.target.value,
									})
								}
							>
								<option value="">= 선 택 =</option>
								<option value="1">회원번호</option>
								<option value="2">닉네임</option>
								<option value="3">이메일</option>
							</select>
							<input
								type="text"
								id="searchWord"
								onChange={(e) =>
									setSearchOptions({
										...searchOptions,
										searchWord: e.target.value,
									})
								}
							/>
							<S.searchButton onClick={() => search()}>
								<S.headerText>검색하기</S.headerText>
							</S.searchButton>
							<S.searchButton
								onClick={() => {
									viewerCheck(() => {
										if (
											window.confirm(
												"엑셀을 다운 받으시겠습니까?"
											)
										) {
											alert("엑셀다운로드 개발 전");
										}
									});
								}}
								color={S.color.primaryVariant}
							>
								<S.headerText>엑셀 다운로드</S.headerText>
							</S.searchButton>
							<S.filterButton
								onClick={() => {
									setOpenFilter(!isOpenFilter);
								}}
								className={`${isApplied && "applied"}`}
							>
								{isOpenFilter ? (
									<Minus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								) : (
									<Plus
										width="2rem"
										height="2rem"
										fill="white"
									/>
								)}
							</S.filterButton>
						</S.searchRow>
						{/* 필터영역 */}
						<S.filterArea className={`${isOpenFilter && "expend"}`}>
							<S.filterWrap>
								{filterList.map((filter: any, fi: number) => {
									return (
										<li key={fi}>
											<h2>{filter.title}</h2>
											<ul>
												<Filter
													fi={fi}
													filter={filter} // 필터 아이템 리스트
													option={filter.option}
													setOption={filter.setOption}
												/>
											</ul>
										</li>
									);
								})}
							</S.filterWrap>
							<S.flexRowEnd>
								<button onClick={() => filter()}>적용</button>
								<button onClick={() => filterRefresh()}>
									전체 해제
								</button>
							</S.flexRowEnd>
						</S.filterArea>
					</S.searchArea>
					{/* 통계 영역 */}
					<S.statsArea>
						<p>
							전체
							<S.headerText>
								{Util.addComma(totalItems)}
							</S.headerText>
							명
						</p>
						{stateCnt && stateCnt.length > 0 ? (
							stateCnt.map((item: any, index: number) => {
								return (
									<p key={index}>
										{item.stateName}
										<S.headerText>
											{Util.addComma(item.cnt)}
										</S.headerText>
										명
									</p>
								);
							})
						) : (
							<></>
						)}
					</S.statsArea>
					{/* 테이블 영역 */}
					<S.table01Wrap>
						<S.table01>
							<thead>
								<tr>
									<th
										className="sort down"
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 1,
											});
											pageResset();
										}}
									>
										회원번호
									</th>
									<th>상태</th>
									<th>닉네임</th>
									<th>이메일</th>
									<th
										className="sort "
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 2,
											});
											pageResset();
										}}
									>
										보유 포인트(P)
									</th>
									<th
										className="sort "
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 3,
											});
											pageResset();
										}}
									>
										보유 상자(개)
									</th>
									<th
										className="sort "
										onClick={(e) => {
											Util.sorting({
												e, // 이벤트 대상
												searchOptionsRef,
												searchOptions,
												sortType: 4,
											});
											pageResset();
										}}
									>
										보유 보물(개)
									</th>
									<th>가입 일시</th>
									<th>최근 접속 일시</th>
									<th>
										마케팅 수신동의{`\n`}(메일, SMS, 푸시)
									</th>
								</tr>
							</thead>
							<tbody>
								{list && list.length > 0 ? (
									<>
										{list.map((item, i) => (
											<tr key={i}>
												<td>{item.mmIdx}</td>
												<td>
													{stateText(item.stateCode)}
												</td>
												<td>{item.nick}</td>
												<td>{item.email}</td>
												<td>
													{Util.addComma(
														item.freePoint
													)}
												</td>
												<td>
													{Util.addComma(
														Number(item.boxCnt)
													)}
												</td>
												<td>
													{Util.addComma(
														Number(item.inventory)
													)}
												</td>
												<td>
													{item.joinDt
														? Util.dateFormat(
																item.joinDt
														  )
														: "-"}
												</td>
												<td>
													{item.lastLogin
														? Util.dateFormat(
																item.lastLogin
														  )
														: "-"}
												</td>
												<td>
													<div className="iconArea">
														<Mail
															fill={
																item.mailSendYn ==
																1
																	? S.color
																			.gray04
																	: S.color
																			.gray02
															}
														/>
														<Sms
															fill={
																item.smsSendYn ==
																1
																	? S.color
																			.gray04
																	: S.color
																			.gray02
															}
														/>
														<Push
															fill={
																false
																	? S.color
																			.gray04
																	: S.color
																			.gray02
															}
														/>
													</div>
												</td>
											</tr>
										))}
									</>
								) : (
									<tr>
										<td
											colSpan={999}
											style={{ textAlign: "center" }}
										>
											데이터가 없습니다.
										</td>
									</tr>
								)}
							</tbody>
						</S.table01>
					</S.table01Wrap>
					{/* 페이지네이션 영역 */}
					<Pagination
						totalItems={totalItems} // 데이터 총 개수
						currentPage={
							page && parseInt(page) > 0 ? parseInt(page) : 1
						} // 현재 페이지
						itemCountPerPage={listSize} // 페이지당 보여줄 데이터 개수
					/>
				</div>
			}
		/>
	);
};
